import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Amplify from "aws-amplify";
import config from "./config";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    //  identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  //Storage: {
  //    region: config.s3.REGION,
  //    bucket: config.s3.BUCKET,
  //    identityPoolId: config.cognito.IDENTITY_POOL_ID
  //},
  API: {
    endpoints: [
      {
        name: "boatsecure",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  }
});

let userName = "";
let userid = "";
let user_payload = "";
let isAuthenticated = false;
let IsMobile = false;
var self = this;
document.addEventListener("message", function(msg) {
  var dataStringify = JSON.parse(msg.data);
  let payload =dataStringify.payload;
  self.userName = payload.username;
  self.userid = payload.sub;
  self.user_payload = payload;
  self.isAuthenticated = true;
  self.IsMobile = dataStringify.IsMobile;
});
ReactDOM.render(
  <App
    userName={userName}
    userid={userid}
    user_payload={user_payload}
    isAuthenticated={isAuthenticated}
    IsMobile={IsMobile}
  />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
