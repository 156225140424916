import React, { Component } from "react";
import {
  Feed,
  Header,
  Segment,
  Grid,
  boat,
  List,
  Popup,
  Label,
  Icon,
  Table,
  Message,
  Button,
  Responsive
} from "semantic-ui-react";
import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react";
import { Circle } from "google-maps-react";
import {
  selectView,
  apiGetMethod,
  convertDBDatetoformatWithTime
} from "../modules/data";
// import VoltageChart from "./charts/VoltageChart";
// import BilgePumpChart from "./charts/BilgePumpChart";
import VoltageChartCopy from "./charts/VoltageChartGoogle";
import BilgePumpChartCopy from "./charts/BilgePumpChartGoogle";
import Carousel from "./carousell/Carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import LatestEventsTable from "./LatestEventsTable";

import { stat } from "fs";
const marker = require("../images/marker.png");
const no_last_location_marker = require("../images/red.png");

// const style = {
//   borderRadius: 0,
//   opacity: 0.7
// };
export class BoatDetailedOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boatName: "",
      batteryVoltage: "",
      ismapLastLcoation: true,
      lastUpdate: "",
      selectedBoat: {},
      isboatDataHas: false,
      showingNoDataInfoWindow: false,
      selectedBoatDetails: {},
      mapViewBoat: {},
      activeMarker: {},
      intial_latitude: "",
      intial_longitude: "",
      showingInfoWindow: false,
      boatData: [],
      mapInfoBoatData: "",
      durationSelector: 24,
      loaderActive: true,
      selectedBoatStypeValues: []
      };
      this.refreshDetailoverViewPage = this.refreshDetailoverViewPage.bind(this);
      this.onMarkerClickDefault = this.onMarkerClickDefault.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    var update = {};
    var boat = {};
    var intial_latitude = null;
      var intial_longitude = null;
      var ismapLastLcoation = false;
    if (nextProps.selectedBoat !== prevState.selectedBoat) {
        var selectedBoat = nextProps.selectedBoat;
        
      if (
          selectedBoat.last_location_stype != null && 
          selectedBoat.last_location_stype[4] != null 
          
      ) {
        intial_latitude = selectedBoat.last_location_stype[4].value.latitude;
          intial_longitude = selectedBoat.last_location_stype[4].value.longitude;
          ismapLastLcoation = true
          update.mapViewBoat = selectedBoat;
          
        }

        if (intial_latitude == null && intial_longitude == null) {
            
        intial_latitude = "-180";
            intial_longitude = "0";
            ismapLastLcoation=false
      }
      update.intial_latitude = intial_latitude;
      update.intial_longitude = intial_longitude;
        update.ismapLastLcoation = ismapLastLcoation;

      console.log("update", update);
    }

    var boat = {};
    if (nextProps.selectedBoat !== prevState.selectedBoatDetails) {
      boat = nextProps.selectedBoat;
      var last_seen_time = null;
      var last_seen_message = null;
        var last_updated_at_show = null;
        var update_segment_color = '#20B2AA'
      if (boat.timestamp_diff != null) {
        let temp_timestamp_diff = boat.timestamp_diff;
        if (temp_timestamp_diff >= 518400) {
          last_seen_time = (temp_timestamp_diff / 518400).toFixed(0);
          last_seen_message = "Year(s) ago";
          last_updated_at_show = boat.last_update_at_show;
        } else if (temp_timestamp_diff >= 43200) {
          last_seen_time = (temp_timestamp_diff / 43200).toFixed(0);
          last_seen_message = "Month(s) ago";
          last_updated_at_show = boat.last_update_at_show;
        } else if (temp_timestamp_diff >= 1440) {
          last_seen_time = (temp_timestamp_diff / 1440).toFixed(0);
          last_seen_message = "Day(s) ago";
          last_updated_at_show = boat.last_update_at_show;
        } else if (temp_timestamp_diff >= 60) {
          last_seen_time = (temp_timestamp_diff / 60).toFixed(0);
          last_seen_message = "hour(s) ago";
          last_updated_at_show = null;
        } else if (0 < temp_timestamp_diff && temp_timestamp_diff < 60) {
          last_seen_time = temp_timestamp_diff;
          last_seen_message = "minutes ago";
          last_updated_at_show = null;
        } else {
          console.log("222222222222", temp_timestamp_diff);
          last_seen_time = "few";
          last_seen_message = "seconds ago";
          last_updated_at_show = null;
          }


          var send_alarm_on = false
          if ((boat.stype != null && boat.stype.length > 0 && boat.stype[0] != null) && (boat.stype[0].value.alarm_1 == 1 ||
              boat.stype[0].value.bilge_alarm == 1 ||
              boat.stype[0].value.reboot == 1 ||
              boat.stype[0].value.relay_status == 1 ||
              boat.stype[0].value.shore_power == 1)) {
              send_alarm_on = true
          }
          if (temp_timestamp_diff > 150) {
              update_segment_color = "#D3D3D3" 
          } else if (send_alarm_on == true) {
              update_segment_color = "#ffcccc"
          }
      }

      boat.last_seen_time = last_seen_time;
      boat.last_seen_message = last_seen_message;
      boat.last_updated_at_show = last_updated_at_show;
        boat.update_segment_color = update_segment_color;
      update.selectedBoatDetails = boat;
    }
    return update;
  }
  async componentDidMount() {
    let boatData = [];
    try {
      //   console.log("props", this.props);
      // boatData = await this.get_boatData();
      // let lastTransmission = new Date(boatData[0].updated_at).toString();
      // var res = lastTransmission.split("GMT");
      // this.setState({
      //   boatName: boatData[0].name,
      //   batteryVoltage: boatData[0].stype[2].value / 1000,
      //   lastUpdate: lastTransmission
      // });
      if (this.props.selectedBoat != null) {
        const path = `/boat/get_messarues_by_duration`;
        let queryStringParameters = {
          device_id: this.props.selectedBoat.device_id,
          duration_hours: "24"
        };
        boatData = await apiGetMethod(path, queryStringParameters);
        // boatData = await this.get_boatData(this.props.selectedBoat.device_id);
        //    console.log("boatDate", boatData);
        this.setState({
          boatData: boatData,
          loaderActive: false,
          selectedBoatStypeValues: this.props.selectedBoat.stype[0].value,
          durationSelector: 24
        });
      }
    } catch (err) {}
    }

    refreshOverViewPage() {
        this.props.refreshOverViewPage()
    }; 
  get_boatData = device_id => {
    const path = `/boat/get_messarues_by_duration`;
    let queryStringParameters = {
      device_id: device_id,
      duration_hours: "24"
    };
    return apiGetMethod(path, queryStringParameters);
  };

  backButtonHandler = () => {
    this.props.callBack(selectView.BoatOverview);
  };

  onMarkerClick = (props, marker, e) => {
    // console.log("e", e);
    // console.log("props", props);
    // console.log("marker", marker);
    this.setState({ mapInfoBoatData: props.name });
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  };

  onMapClick = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
    };

    onMarkerClickDefault = (props, marker, e) => {
        this.setState({
            noBoatDataMsg:
                "There is no user last location has been defined for this fleet"
        });
        this.setState({
            activeMarkerNoLastLocation: marker,
            showingNoDataInfoWindow: true
        });
    };
  durationSelector = async duration => {
    this.setState({ loaderActive: true });
    const path = `/boat/get_messarues_by_duration`;
    let queryStringParameters = {
      device_id: this.props.selectedBoat.device_id,
      duration_hours: duration
    };
    let boatData = await apiGetMethod(path, queryStringParameters);
    this.setState({
      boatData: boatData,
      durationSelector: duration,
      loaderActive: false
    });
    };
    refreshDetailoverViewPage() {
        this.props.refreshDetailoverViewPage()
    }; 
  gotoDataLogView = () => {
    //  console.log("data view log")
    this.props.callBack(selectView.DataLogView, this.state.boatData);
  };
  render() {
    const style = {
      width: "95vw",
      height: "80vh",
      marginLeft: "0",
      marginRight: "auto"
    };
    const {
      selectedBoatStypeValues,
      mapInfoBoatData,
      durationSelector,
        selectedBoatDetails,
        ismapLastLcoation,
        showingNoDataInfoWindow
    } = this.state;
    var mapViewBoat = {};
    mapViewBoat = this.state.mapViewBoat;
    if (mapViewBoat != null) {
      mapViewBoat.marker = marker;
    }

    var last_seen_time = null;
    var last_seen_message = null;
    var fill_color = null;
    if (
      mapViewBoat.timestamp_diff != null &&
      mapViewBoat.timestamp_diff != ""
    ) {
      var last_seen_time = null;
      var last_seen_message = null;
      var last_updated_at_show = null;
      if (
        mapViewBoat.timestamp_diff != null &&
        mapViewBoat.timestamp_diff != ""
      ) {
        let temp_timestamp_diff = mapViewBoat.timestamp_diff;
        if (temp_timestamp_diff >= 518400) {
          last_seen_time = (temp_timestamp_diff / 518400).toFixed(0);
          last_seen_message = "Year(s) ago";
            last_updated_at_show = null;
        } else if (temp_timestamp_diff >= 43200) {
          last_seen_time = (temp_timestamp_diff / 43200).toFixed(0);
          last_seen_message = "Month(s) ago";
            last_updated_at_show = null;
        } else if (temp_timestamp_diff >= 1440) {
          last_seen_time = (temp_timestamp_diff / 1440).toFixed(0);
          last_seen_message = "Day(s) ago";
            last_updated_at_show = null;
        } else if (temp_timestamp_diff >= 60) {
          last_seen_time = (temp_timestamp_diff / 60).toFixed(0);
          last_seen_message = "hour(s) ago";
          last_updated_at_show = null;
        } else {
          last_seen_time = temp_timestamp_diff;
          last_seen_message = "minutes ago";
          last_updated_at_show = null;
        }
      }
    }
    mapViewBoat.last_seen_time = last_seen_time;
    mapViewBoat.last_seen_message = last_seen_message;
    mapViewBoat.last_updated_at_show = last_updated_at_show;
    var fill_color = null;
    if (
      mapViewBoat.last_location_stype != null &&
      mapViewBoat.last_location_stype[4] != null
    ) {
      let boat_drift = mapViewBoat.last_location_stype[4].value.drift;
      if (boat_drift >= 50) {
        fill_color = "#ffcccc";
      } else {
        fill_color = "#00cc99";
      }
    }
    mapViewBoat.fill_color = fill_color;

    //  console.log('mapViewBoat', mapViewBoat)
    // console.log('this.state', this.state)
    // if (mapViewBoat!='') {
    // mapViewBoat.marker = marker
    // }

    var isboatDataHas;
    if (mapInfoBoatData == "") {
      isboatDataHas = false;
    } else {
      isboatDataHas = true;
    }

    return (
      <React.Fragment>
        <React.Fragment>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                            <Segment style={{ backgroundColor: selectedBoatDetails.update_segment_color }}  inverted>
                                <Label style={{ backgroundColor: selectedBoatDetails.update_segment_color }}  >
                                <Header as='h4'> <Header.Content>
                                    {this.props.selectedBoat.device_name}
                                    <Header.Subheader>{this.props.selectedBoat.device_eid}</Header.Subheader>
                                    <Header.Subheader>Updated:{""}{this.state.selectedBoatDetails.last_updated_at_show != null ? this.state.selectedBoatDetails.last_updated_at_show : ''} {this.state.selectedBoatDetails.last_seen_time != null
                                        ?  this.state.selectedBoatDetails.last_seen_time + ' ' + this.state.selectedBoatDetails.last_seen_message
                                        : ""}</Header.Subheader>
                                    </Header.Content> </Header>
                                    </Label>
                  <Button
                    floated="right"
                    circular
                    size='mini'
                    icon="arrow left"
                    color="blue"
                    onClick={this.backButtonHandler}
                                />
                                {"    "}
                                <Button
                                    //attached='left'
                                    floated="right"
                                    circular
                                    icon="refresh"
                                    color="blue"
                                    size='mini'
                                    onClick={this.refreshDetailoverViewPage}
                                />
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Popup
                  trigger={
                    <Label
                      color={
                        selectedBoatStypeValues.shore_power == 0
                          ? "teal"
                          : "yellow"
                      }
                    >
                      Shore power
                    </Label>
                  }
                  content={
                    selectedBoatStypeValues.shore_power == 0
                      ? "Shore power is On"
                      : "Shore power is Off"
                  }
                  inverted
                />{" "}
                <Popup
                  trigger={
                    <Label
                      color={
                        selectedBoatStypeValues.bilge_alarm == 0
                          ? "teal"
                          : "yellow"
                      }
                    >
                      Bilge alarm
                    </Label>
                  }
                  content={
                    selectedBoatStypeValues.bilge_alarm == 0
                      ? "Bilge alarm is off"
                      : "Bilge alarm is on"
                  }
                  // style={style}
                  inverted
                />{" "}
                <Popup
                  trigger={
                    <Label
                      color={
                        selectedBoatStypeValues.gps == 1 ? "teal" : "yellow"
                      }
                    >
                      Anchor drift
                    </Label>
                  }
                  content={
                    selectedBoatStypeValues.gps == 0
                      ? "Anchor drift is off"
                      : "Anchor drift is on"
                  }
                  //style={style}
                  inverted
                />{" "}
                <Popup
                  trigger={
                    <Label
                      color={
                        selectedBoatStypeValues.alarm_1 == 0 ? "teal" : "yellow"
                      }
                    >
                      Relay alarm
                    </Label>
                  }
                  content={
                    selectedBoatStypeValues.alarm_1 == 0
                      ? "Relay alarm is off"
                      : "Relay alarm is on"
                  }
                  // style={style}
                  inverted
                />{" "}
                <Popup
                  trigger={
                    <Label
                      color={
                        selectedBoatStypeValues.relay_status == 0
                          ? "teal"
                          : "yellow"
                      }
                    >
                      Relay switch
                    </Label>
                  }
                  content={
                    selectedBoatStypeValues.relay_status == 0
                      ? "Relay switch is off"
                      : "Relay switch is on"
                  }
                  //  style={style}
                  inverted
                />
              </Grid.Column>
            </Grid.Row>

            {this.state.boatData.length == 0 && !this.state.loaderActive ? (
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Message color="teal">
                    There is no data for the selected duration
                  </Message>
                </Grid.Column>
              </Grid.Row>
            ) : (
              <React.Fragment>
                <Grid.Row>
                  <Grid.Column textAlign="center">
                    {" "}
                    <Responsive
                      as={Segment}
                      maxWidth={1050}
                      style={{ backgroundColor: "#e0fffd" }}
                    >
                      <Carousel
                        boatData={this.state.boatData}
                        durationSelector={this.state.durationSelector}
                      />
                    </Responsive>
                  </Grid.Column>
                </Grid.Row>

                {/* <Grid.Row columns={2}>
                  <Grid.Column>
                    <Responsive
                      as={Segment}
                      loading={this.state.loaderActive}
                      minWidth={1051}
                      //  color="teal"  inverted   tertiary
                      style={{ backgroundColor: "#e0fffd" }}
                    >
                      <VoltageChart
                        boatData={this.state.boatData}
                        durationSelector={this.state.durationSelector}
                      />
                    </Responsive>
                  </Grid.Column>
                  <Grid.Column>
                    <Responsive
                      as={Segment}
                      loading={this.state.loaderActive}
                      minWidth={1051}
                      style={{ backgroundColor: "#e0fffd" }}
                    >
                      <BilgePumpChart
                        boatData={this.state.boatData}
                        durationSelector={this.state.durationSelector}
                      />
                    </Responsive>
                  </Grid.Column>
                </Grid.Row> */}
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Responsive
                      as={Segment}
                      loading={this.state.loaderActive}
                      minWidth={1051}
                      //  color="teal"  inverted   tertiary
                      style={{ backgroundColor: "#e0fffd" }}
                    >
                      <VoltageChartCopy
                        boatData={this.state.boatData}
                        durationSelector={this.state.durationSelector}
                      />
                    </Responsive>
                  </Grid.Column>
                  <Grid.Column>
                    <Responsive
                      as={Segment}
                      loading={this.state.loaderActive}
                      minWidth={1051}
                      style={{ backgroundColor: "#e0fffd" }}
                    >
                      <BilgePumpChartCopy
                        boatData={this.state.boatData}
                        durationSelector={this.state.durationSelector}
                      />
                    </Responsive>
                  </Grid.Column>
                </Grid.Row>
              </React.Fragment>
            )}
            <Grid.Row>
              {" "}
              <Grid.Column textAlign="center">
                <Button
                  color="blue"
                  //  active={true }
                  basic={durationSelector === 6 ? false : true}
                  onClick={() => this.durationSelector(6)}
                >
                  <Icon name="calendar alternate outline" />6 Hours
                </Button>
                <Button
                  color="blue"
                  //  active={durationSelector === 24 ? true : false}
                  basic={durationSelector === 24 ? false : true}
                  onClick={() => this.durationSelector(24)}
                >
                  <Icon name="calendar alternate outline" /> 24 Hours
                </Button>
                <Button
                  color="blue"
                  onClick={() => this.durationSelector(7 * 24)}
                  // active={durationSelector===(7*24)? true:false}
                  basic={durationSelector === 7 * 24 ? false : true}
                >
                  <Icon name="calendar alternate outline" />7 Days
                </Button>
                <Button
                  color="blue"
                  onClick={() => this.durationSelector(30 * 24)}
                  // active={durationSelector===(30*24)? true:false}
                  basic={durationSelector === 30 * 24 ? false : true}
                >
                  <Icon name="calendar alternate outline" />
                  30 Days
                </Button>
                <Button color="blue" basic onClick={this.gotoDataLogView}>
                  View Data
                </Button>
              </Grid.Column>
            </Grid.Row>
            {/* <Grid.Row>
                  <Grid.Column>
                    <Button color="blue" basic onClick={this.gotoDataLogView}>
                      View Data for this duration
                    </Button>
                  </Grid.Column>
                </Grid.Row> */}
            <Grid.Row>
              <Grid.Column>
                <Header> Last known location</Header>
                <Segment.Group>
                  <Responsive as={Segment}>
                    <div
                      style={{
                        height: 500,
                        // width: 500,
                        //  float: "left",
                        position: "relative"
                      }}
                    >
                      <Map
                        google={this.props.google}
                        zoom={14}
                        //style={style}
                        initialCenter={{
                          lat: this.state.intial_latitude,
                          lng: this.state.intial_longitude
                        }}
                        
                                        >
                                            {mapViewBoat.last_location_stype != null &&
                                                mapViewBoat.last_location_stype[4] != "" ? (
                        <Circle
                          center={{
                            lat:
                              mapViewBoat.last_location_stype[4].value.latitude,
                            lng:
                              mapViewBoat.last_location_stype[4].value.longitude
                          }}
                          //radius={3}
                          radius={50}
                          //onMouseover={() => console.log('mouseover')}
                          //onClick={() => console.log('click')}
                          //onMouseout={() => console.log('mouseout')}
                          strokeColor="transparent"
                          strokeOpacity={0}
                          strokeWeight={5}
                          fillColor={mapViewBoat.fill_color}
                          fillOpacity={0.2}
                          //onCenterChanged={this.onCenterChanged}
                          //onRadiusChanged={this.onRadiusChanged}
                        />
                                                ) : null}
                        {mapViewBoat.last_location_stype != null &&
                        mapViewBoat.last_location_stype[4] != "" ? (
                          <Marker
                            key={mapViewBoat.device_id}
                            onClick={this.onMarkerClick}
                            title={mapViewBoat.device_name}
                            position={{
                              lat:
                                mapViewBoat.last_location_stype[4].value
                                  .latitude,
                              lng:
                                mapViewBoat.last_location_stype[4].value
                                  .longitude
                            }}
                            name={mapViewBoat}
                            icon={{
                              url: `${mapViewBoat.marker}`,
                              //anchor: new this.props.google.maps.Point(32, 32),
                              scaledSize: new this.props.google.maps.Size(
                                16,
                                30
                              )
                            }}
                          />
                                                ) : null}
                                            {ismapLastLcoation == false ? (
                                                <Marker
                                                    key={mapViewBoat.device_id}
                                                    onClick={this.onMarkerClickDefault}
                                                    //title={boat.device_name}
                                                    position={{
                                                        lat: this.state.intial_latitude,
                                                        lng: this.state.intial_longitude
                                                    }}
                                                    name={"Default user last lcoation"}
                                                    icon={{
                                                        url: `${no_last_location_marker}`,
                                                        //anchor: new this.props.google.maps.Point(32, 32),
                                                        scaledSize: new this.props.google.maps.Size(16, 30)
                                                    }}
                                                />
                                            ) : null}
                        <InfoWindow
                          marker={this.state.activeMarker}
                          visible={this.state.showingInfoWindow}
                        >
                          {isboatDataHas == true ? (
                            <List divided relaxed>
                              <List.Item>
                                <b>Boat Name:</b>
                                {mapInfoBoatData.device_name != null
                                  ? mapInfoBoatData.device_name
                                  : ""}
                              </List.Item>
                              <List.Item>
                                <b>Last updated:</b>
                                {"  "}
                                {mapInfoBoatData.last_updated_at_show != null
                                  ? mapInfoBoatData.last_updated_at_show
                                  : ""}{" "}
                                {mapInfoBoatData.last_seen_time != null
                                  ? " about " +
                                    mapInfoBoatData.last_seen_time +
                                    "  " +
                                    mapInfoBoatData.last_seen_message
                                  : ""}
                              </List.Item>
                              <List.Item>
                                <b>Latitude:</b>
                                {mapInfoBoatData.last_location_stype[4].value
                                  .latitude != null
                                  ? mapInfoBoatData.last_location_stype[4].value.latitude.toFixed(
                                      6
                                    )
                                  : ""}
                              </List.Item>
                              <List.Item>
                                <b>Longitude:</b>
                                {mapInfoBoatData.last_location_stype[4].value
                                  .longitude != null
                                  ? mapInfoBoatData.last_location_stype[4].value.longitude.toFixed(
                                      6
                                    )
                                  : ""}
                              </List.Item>
                              <List.Item>
                                <b>Internal Battery:</b>
                                {mapInfoBoatData.stype[1].value != null
                                  ? (
                                      mapInfoBoatData.last_location_stype[1]
                                        .value / 1000
                                    ).toFixed(0)
                                  : "0"}
                                V
                              </List.Item>
                              <List.Item>
                                <b>Boat Battery:</b>
                                {mapInfoBoatData.last_location_stype[2].value !=
                                null
                                  ? (
                                      mapInfoBoatData.last_location_stype[2]
                                        .value / 1000
                                    ).toFixed(0)
                                  : "0"}
                                V
                              </List.Item>
                              <List.Item>
                                <b>Drift:</b>
                                {mapInfoBoatData.last_location_stype[4].value
                                  .drift != null
                                  ? mapInfoBoatData.last_location_stype[4].value
                                      .drift
                                  : "0"}
                                m
                              </List.Item>
                            </List>
                          ) : null}
                                            </InfoWindow>
                                            {showingNoDataInfoWindow == true ? (
                                                <InfoWindow
                                                    marker={this.state.activeMarkerNoLastLocation}
                                                    visible={this.state.showingNoDataInfoWindow}
                                                //key={this.props.selectedFleet.asset_id}
                                                >
                                                    <List divided relaxed>
                                                        <List.Item>
                                                            <b>
                                                                No location data for{" "}
                                                                {this.props.fleetName}
                                                            </b>
                                                        </List.Item>
                                                    </List>
                                                </InfoWindow>
                                            ) : null}
                      </Map>
                    </div>
                  </Responsive>
                </Segment.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </React.Fragment>
      </React.Fragment>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyDLx6yzrlmjQTQHnV9rKzqZS6k-qhsZE6M",
  mapTypeId: "satellite"
})(BoatDetailedOverview);
