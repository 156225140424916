import { CarouselProvider, Slider, Dot, Slide } from "pure-react-carousel"; 
import React, { Component } from "react";
import VoltageChartG from "../charts/VoltageChartGoogle";
import BilgePumpChartG from "../charts/BilgePumpChartGoogle"; 
// import VoltageChart from "../charts/VoltageChart";
// import BilgePumpChart from "../charts/BilgePumpChart"; 

import { Button, Icon } from "semantic-ui-react";
import 'pure-react-carousel/dist/react-carousel.es.css';
export default class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <CarouselProvider
        naturalSlideWidth={1}
        naturalSlideHeight={1.2}
        totalSlides={2}
        // naturalSlideWidth={100}
        // naturalSlideHeight={120} 
      > 
        <Slider>
          <Slide className="slide" index={0}>
          
            {/* <VoltageChartRN */}
              <VoltageChartG
              boatData={this.props.boatData}
              durationSelector={this.props.durationSelector}
            />
          </Slide>
          <Slide index={1}>
           
            {/* <BilgePumpChartRN */}
             <BilgePumpChartG
              boatData={this.props.boatData}
              durationSelector={this.props.durationSelector}
            />
          </Slide>
        </Slider>
        <Button as={Dot} size="mini" circular slide={0} color="blue" basic>
          <Icon name="arrow circle left" />
        </Button>
        <Button as={Dot} circular size="mini" slide={1} color="blue" basic>
          <Icon name="arrow circle right" />
        </Button>
      </CarouselProvider>
    );
  }
}
