import React, { Component } from "react";
import {
  Image,
  Card,
  Header,
  Grid,
  Responsive,
  List,
  Segment,
  Button,
  Modal,
  Label
} from "semantic-ui-react";
import BoatOverview from "./BoatOverview";
import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react";
import { selectView, convertDBDatetoformat } from "../modules/data";
import { apiPostMethod } from "../../src/modules/data";
const marker = require("../images/marker.png");
const no_last_location_marker = require("../images/red.png");

export class Boatlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapTypeId: "satellite",
      boatData: "",
      boatList: "",
      ismapLastLcoation: true,
      flag_map_loading: true,
      triangleCoords: [],
      userLastLocationMap: {},
      mapViewBoatList: "",
      showingNoDataInfoWindow: false,
      intial_latitude: "",
      intial_longitude: "",
      showingInfoWindow: false,
      noBoatDetails: false
    };

    this.onMarkerClick = this.onMarkerClick.bind(this);
    //this.onMapClick = this.onMapClick.bind(this);
    this.fetchPlaces = this.fetchPlaces.bind(this);
    this.centerMoved = this.centerMoved.bind(this);
    this.onCenterChanged = this.onCenterChanged.bind(this);
    this.onMarkerClickDefault = this.onMarkerClickDefault.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
  }

  componentDidMount() {
    console.log("boatlist", this.props);
  }

  static isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }
  static getDerivedStateFromProps(props, state) {
    var update = {};
    console.log("nextProps", props);
    console.log("prevState", state);
    var intial_latitude = null;
    var intial_longitude = null;
    if (props.boatList !== state.boatList) {
      var boatList = [];
      var ismapLastLcoation;
      var showingNoDataInfoWindow;
      boatList = props.boatList;

      if (
        props.selectedFleet.asset_map_last_location != null &&
        !Boatlist.isEmpty(props.selectedFleet.asset_map_last_location)
      ) {
        console.log(
          "props.selectedFleet.asset_map_last_location",
          props.selectedFleet.asset_map_last_location
        );
        let map_last_location = {};
        map_last_location = props.selectedFleet.asset_map_last_location;
        intial_latitude = map_last_location.lat;
        intial_longitude = map_last_location.lng;
        ismapLastLcoation = true;
        showingNoDataInfoWindow = false;
      } else {
        console.log("intial_latitude", intial_latitude);
        console.log("intial_longitude", intial_longitude);
        if (intial_latitude == null && intial_longitude == null) {
          intial_latitude = "0";
          intial_longitude = "-180";
          ismapLastLcoation = false;
          showingNoDataInfoWindow = true;
        }
      }

      update.intial_latitude = intial_latitude;
      update.intial_longitude = intial_longitude;
      update.ismapLastLcoation = ismapLastLcoation;
      update.showingNoDataInfoWindow = showingNoDataInfoWindow;
      var mapViewBoatList = [];
      for (let i = 0; i < boatList.length; i++) {
        // console.log("boatList[i]", boatList[i]);
        if (
          boatList[i].last_location_stype != null &&
          boatList[i].last_location_stype[4] &&
          boatList[i].last_location_stype[4].value.latitude != "" &&
          boatList[i].last_location_stype[4].value.longitude != ""
        ) {
          mapViewBoatList.push(boatList[i]);
        }
      }
      update.mapViewBoatList = mapViewBoatList;
      update.boatList = boatList;
    }
    return update;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.flag_map_loading == true) {
      return true;
    } else {
      return false;
    }
  }
  callbackFunction = e => {
    // console.log("callbackfunction", e);
    if (e.stype == null || e.stype.length == 0) {
      this.setState({ noBoatDetails: true });
    } else this.props.callBack(selectView.BoatOverview, e);
  };
  backButtonHandler = () => {
    this.props.callBack(selectView.FleetList);
  };
  refreshPage() {
    //window.location.reload(true);
    this.props.refreshPage();
    //this.props.callBack(selectView.BoatList);
  }

  onMarkerClick = (props, marker, e) => {
    //  console.log("props.name", props.name);
    // console.log("props", props);
    // console.log("marker", marker);
    this.setState({ boatData: props.name });
    this.setState({
      flag_map_loading: true,
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  };

  fetchPlaces(mapProps, map) {
    //   console.log("mapProps", mapProps);
    //    console.log("map", map);
    const { google } = mapProps;
    const service = new google.maps.places.PlacesService(map);
    //   console.log("service", service);
    // ...
  }

  onMarkerClickDefault = (props, marker, e) => {
    this.setState({
      noBoatDataMsg:
        "There is no user last location has been defined for this fleet"
    });
    this.setState({
      activeMarkerNoLastLocation: marker,
      showingNoDataInfoWindow: true
    });
  };
  onCenterChanged(mapProps, map) {
    // console.log("3333333333333", mapProps);
    // console.log("444444444444444", map);
    const { google } = mapProps;
    const service = new google.maps.places.PlacesService(map);
    //  console.log("ttttttttttttt", service);
    // ...
  }

  //onMapClick = props => {
  //  if (this.state.showingInfoWindow) {
  //    this.setState({
  //      showingInfoWindow: false,
  //      activeMarker: null
  //    });
  //  }
  //};

  async centerMoved(mapProps, map) {
    this.setState({ flag_map_loading: false });
    console.log("111111111111", mapProps);
    console.log("22222222222222", map);
    var fleet_last_location = {};
    let user_last_location = {};
    user_last_location.lat = map.center.lat();
    user_last_location.lng = map.center.lng();
    fleet_last_location.asset_eid = this.props.selectedFleet.asset_eid;
    fleet_last_location.user_last_location = user_last_location;
    await this.save_user_last_location(fleet_last_location);
    await this.props.refreshPage();
  }
  noBoatDetailsModalClose = () => {
    this.setState({ noBoatDetails: false });
  };

  save_user_last_location = e => {
    const path = `/boat/save_last_map_location`;
    let user_info = {
      user_id: this.props.userid,
      userLastLocationMap: e
    };
    return apiPostMethod(path, user_info);
  };

  render() {
    const {
      mapTypeId,
      boatData,
      ismapLastLcoation,
      showingNoDataInfoWindow
    } = this.state;

    //console.log("props", this.props);
    var isboatDataHas;
    const coords = { lat: -21.805149, lng: -49.0921657 };
    if (boatData == "") {
      isboatDataHas = false;
    } else {
      isboatDataHas = true;
    }
    var mapViewBoatList = this.state.mapViewBoatList;
    mapViewBoatList.map(boat => {
      boat.marker = marker;
      var last_seen_time = null;
      var last_seen_message = null;
      if (boat.timestamp_diff != null && boat.timestamp_diff != "") {
        let temp_timestamp_diff = boat.timestamp_diff;
        if (temp_timestamp_diff >= 1440) {
          last_seen_time = (temp_timestamp_diff / 1440).toFixed(0);
          last_seen_message = "Day(s) ago";
        } else if (temp_timestamp_diff >= 60) {
          last_seen_time = (temp_timestamp_diff / 60).toFixed(0);
          last_seen_message = "hour(s) ago";
        } else {
          last_seen_time = temp_timestamp_diff;
          last_seen_message = "minutes ago";
        }
      }
      boat.last_seen_time = last_seen_time;
      boat.last_seen_message = last_seen_message;
    });

    var boatList = this.props.boatList;
    var key = "device_name";
    function commonSortingHandler(boatList, key) {
      function compare(a, b) {
        a = a[key];
        b = b[key];
        var type =
          typeof a === "string" || typeof b === "string" ? "string" : "number";
        var result;
        if (type === "string") result = a.localeCompare(b);
        else result = a - b;
        return result;
      }
      return boatList.sort(compare);
    }

    boatList = commonSortingHandler(boatList, key);
      console.log("boatList", boatList);
    var update_segment_color='teal'
    boatList.map(boat => {
      var last_seen_time = null;
      var last_seen_message = null;
      var show_alarm_color = null;
      var last_updated_at_show = null;
      if (boat.timestamp_diff != null) {
        let temp_timestamp_diff = boat.timestamp_diff;
        if (temp_timestamp_diff >= 518400) {
          console.log("ttttttttttttttt", temp_timestamp_diff);
          last_seen_time = (temp_timestamp_diff / 518400).toFixed(0);
          last_seen_message = "Year(s) ago";
          last_updated_at_show = null;
        } else if (temp_timestamp_diff >= 43200) {
          last_seen_time = (temp_timestamp_diff / 43200).toFixed(0);
          last_seen_message = "Month(s) ago";
          last_updated_at_show = null;
        } else if (temp_timestamp_diff >= 1440) {
          last_seen_time = (temp_timestamp_diff / 1440).toFixed(0);
          last_seen_message = "Day(s) ago";
            last_updated_at_show = null;
           
        } else if (temp_timestamp_diff >= 60) {
          last_seen_time = (temp_timestamp_diff / 60).toFixed(0);
          last_seen_message = "hour(s) ago";
            last_updated_at_show = null;
            
        } else if (0 < temp_timestamp_diff && temp_timestamp_diff < 60) {
          console.log("11111111", temp_timestamp_diff);
          last_seen_time = temp_timestamp_diff;
          last_seen_message = "minutes ago";
          last_updated_at_show = null;
        } else {
          console.log("222222222222", temp_timestamp_diff);
          last_seen_time = "few";
          last_seen_message = "seconds ago";
          last_updated_at_show = null;
        }
      }
      boat.last_seen_time = last_seen_time;
      boat.last_seen_message = last_seen_message;
      boat.last_updated_at_show = last_updated_at_show;
    });
    return (
      <React.Fragment>
        <Grid columns="equal" centered>
          <Grid.Row>
            <Grid.Column>
                        <Segment color={update_segment_color} inverted>
                            <Label color={update_segment_color}>
                  {" "}
                  <h4> {this.props.fleetName} </h4>{" "}
                </Label>

                <Button
                  floated="right"
                  circular
                  icon="arrow left"
                  color="blue"
                  size="mini"
                  onClick={this.backButtonHandler}
                />

                {"                     "}
                <Button
                  floated="right"
                  circular
                  icon="refresh"
                  color="blue"
                  size="mini"
                  onClick={this.refreshPage}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Segment.Group>
                <Responsive as={Segment}>
                  <div
                    style={{
                      height: 500,
                      position: "relative"
                    }}
                  >
                    <Map
                      google={this.props.google}
                      zoom={8}
                      //style={style}
                      onDragend={this.centerMoved}
                      initialCenter={{
                        lat: this.state.intial_latitude,
                        lng: this.state.intial_longitude
                      }}
                    >
                      {mapViewBoatList.map(boat => (
                        <Marker
                          key={boat.device_id}
                          onClick={this.onMarkerClick}
                          title={boat.device_name}
                          position={{
                            lat: boat.last_location_stype[4].value.latitude,
                            lng: boat.last_location_stype[4].value.longitude
                          }}
                          name={boat}
                          icon={{
                            url: `${boat.marker}`,
                            //anchor: new this.props.google.maps.Point(32, 32),
                            scaledSize: new this.props.google.maps.Size(16, 30)
                          }}
                        />
                      ))}

                      {ismapLastLcoation == false ? (
                        <Marker
                          key={this.props.selectedFleet.asset_id}
                          onClick={this.onMarkerClickDefault}
                          //title={boat.device_name}
                          position={{
                            lat: this.state.intial_latitude,
                            lng: this.state.intial_longitude
                          }}
                          name={"Default user last lcoation"}
                          icon={{
                            url: `${no_last_location_marker}`,
                            //anchor: new this.props.google.maps.Point(32, 32),
                            scaledSize: new this.props.google.maps.Size(16, 30)
                          }}
                        />
                      ) : null}

                      {isboatDataHas == true ? (
                        <InfoWindow
                          marker={this.state.activeMarker}
                          visible={this.state.showingInfoWindow}
                          key={boatData.device_id}
                        >
                          <List divided relaxed>
                            <List.Item>
                              <b>Boat Name:</b>
                              {boatData.device_name != null
                                ? boatData.device_name
                                : ""}
                            </List.Item>
                            <List.Item>
                              <b>Updated:</b>
                              {""}
                              {boatData.last_updated_at_show != null
                                ? boatData.last_updated_at_show
                                : ""}{" "}
                              {boatData.last_seen_time != null
                                ? boatData.last_seen_time +
                                  "" +
                                  boatData.last_seen_message
                                : ""}
                            </List.Item>
                            <List.Item>
                              <b>Latitude:</b>
                              {boatData.last_location_stype != null &&
                              boatData.last_location_stype[4].value.latitude !=
                                null
                                ? boatData.last_location_stype[4].value.latitude.toFixed(
                                    6
                                  )
                                : ""}
                            </List.Item>
                            <List.Item>
                              <b>Longitude:</b>
                              {boatData.last_location_stype != null &&
                              boatData.last_location_stype[4].value.longitude !=
                                null
                                ? boatData.last_location_stype[4].value.longitude.toFixed(
                                    6
                                  )
                                : ""}
                            </List.Item>
                            <List.Item>
                              <b>Internal Battery:</b>
                              {boatData.last_location_stype != null &&
                              boatData.last_location_stype[1].value != null
                                ? (
                                    boatData.last_location_stype[1].value / 1000
                                  ).toFixed(0)
                                : "0"}
                              V
                            </List.Item>
                            <List.Item>
                              <b>Boat Battery:</b>
                              {boatData.last_location_stype != null &&
                              boatData.last_location_stype[2].value != null
                                ? (
                                    boatData.last_location_stype[2].value / 1000
                                  ).toFixed(0)
                                : "0"}
                              V
                            </List.Item>
                            <List.Item>
                              <b>Drift:</b>
                              {boatData.last_location_stype != null &&
                              boatData.last_location_stype[4].value.drift !=
                                null
                                ? boatData.last_location_stype[4].value.drift
                                : "0"}
                              m
                            </List.Item>
                          </List>
                        </InfoWindow>
                      ) : null}

                      {showingNoDataInfoWindow == true ? (
                        <InfoWindow
                          marker={this.state.activeMarkerNoLastLocation}
                          visible={this.state.showingNoDataInfoWindow}
                          //key={this.props.selectedFleet.asset_id}
                        >
                          <List divided relaxed>
                            <List.Item>
                              <b>
                                No location data for{" "}
                                {this.props.selectedFleet.asset_name}
                              </b>
                            </List.Item>
                          </List>
                        </InfoWindow>
                      ) : null}
                    </Map>
                  </div>
                </Responsive>
              </Segment.Group>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Responsive as={Segment}>
                <Card.Group>
                  {boatList.length == 0 || boatList[0].device_id == null ? (
                    <Header>No boats yet</Header>
                  ) : (
                    boatList.map(boat => (
                    <Card
                      href="#"
                      onClick={() => this.callbackFunction(boat)}
                      key={boat.device_id}
                    >
                      <Card.Content>
                        <Image src="asset.png" wrapped ui={false} />
                      </Card.Content>
                      <Segment
                        tertiary
                        color={
                          boat.stype == null || boat.stype.length == 0
                                ? "grey"
                                : boat.timestamp_diff > 150 ? "grey" 
                            : boat.stype[0].value.global_alarm == 1 ||
                              boat.stype[0].value.alarm_1 == 1 ||
                              boat.stype[0].value.bilge_alarm == 1 ||
                              //   boat.stype[0].value.gps == 0 ||
                              boat.stype[0].value.reboot == 1 ||
                              boat.stype[0].value.relay_status == 1 ||
                              boat.stype[0].value.shore_power == 1 //||
                            ? "red"//      boat.stype[0].value.solar_power == 1
                            : "teal"
                        }
                        inverted
                      >
                        <Header>{boat.device_name}</Header>
                        <Button
                          floated="right"
                          circular
                          icon={
                            boat.stype == null || boat.stype.length == 0
                              ? "anchor"
                              : boat.stype[0].value.global_alarm == 1 ||
                                boat.stype[0].value.alarm_1 == 1 ||
                                boat.stype[0].value.bilge_alarm == 1 ||
                                //   boat.stype[0].value.gps == 0 ||
                                boat.stype[0].value.reboot == 1 ||
                                //     boat.stype[0].value.relay_status == 1 ||
                                boat.stype[0].value.shore_power == 1 //||
                              ? //     boat.stype[0].value.solar_power == 1
                                "exclamation"
                              : "anchor"
                          }
                          color={
                            boat.stype == null || boat.stype.length == 0
                              ? "blue"
                              : boat.stype[0].value.global_alarm == 1 ||
                                boat.stype[0].value.alarm_1 == 1 ||
                                boat.stype[0].value.bilge_alarm == 1 ||
                                //   boat.stype[0].value.gps == 0 ||
                                boat.stype[0].value.reboot == 1 ||
                                boat.stype[0].value.relay_status == 1 ||
                                boat.stype[0].value.shore_power == 1 //||
                              ? //      boat.stype[0].value.solar_power == 1
                                "red"
                              : "blue"
                          }
                          size="medium"
                          //   onClick={this.backButtonHandler}
                        />
                        {/* <Header.Subheader> */}
                        <span>
                          <h6>
                            Last Updated:{" "}
                            {boat.last_updated_at_show != null
                              ? boat.last_updated_at_show
                              : ""}{" "}
                            {boat.last_seen_time != null
                              ? "about " +
                                boat.last_seen_time +
                                "  " +
                                boat.last_seen_message
                              : ""}
                          </h6>
                        </span>
                        {/* </Header.Subheader> */}
                      </Segment>
                    </Card>
                  ))
                  )}
                 
                </Card.Group>
              </Responsive>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {this.state.noBoatDetails ? (
          <Modal
            size="mini"
            open={this.state.noBoatDetails}
            onClose={this.noBoatDetailsModalClose}
          >
            <Modal.Content>
              <p>This Boat has not sent any messages yet!</p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                positive
                icon="checkmark"
                labelPosition="left"
                content="Ok"
                onClick={this.noBoatDetailsModalClose}
              />
            </Modal.Actions>
          </Modal>
        ) : null}
      </React.Fragment>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDLx6yzrlmjQTQHnV9rKzqZS6k-qhsZE6M"
})(Boatlist);
