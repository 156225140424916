import React, { PureComponent, Component } from "react";
// // import ReactEcharts from "echarts-for-react";
// import ReactEchartsCore from "echarts-for-react/lib/core";
// import echarts from "echarts/lib/echarts";
// // import "echarts/lib/chart/bar";
// import 'echarts/lib/chart/line';
// import "echarts/lib/component/tooltip";
// // import "echarts/lib/component/title";
import Chart from "react-google-charts";
import { getVoltageChartDetailsGoogle } from "../../modules/data";
import { Header, Grid, Label } from "semantic-ui-react";

export default class VoltageChart extends PureComponent {
  componentDidMount() {}

  getOption = () => {
    //  console.log("props voltage", this.props);
    const chartData = getVoltageChartDetailsGoogle(
      this.props.boatData,
      this.props.durationSelector
    );
    return chartData.voltData;
  };

  render() {
    return (
      <div
        style={{
          // display: 'flex',
          maxWidth: 950
        }}
      >
        {/* <Header>Boat & Device Battery</Header> */}
        <Chart
          // width={"600px"}
          height={"300px"}
          chartType="LineChart"
          loader={<div>Loading Chart</div>}
            data={this.getOption()}
          // data={[
          //   ["x", "dogs"],
          //   [0, 0],
          //   [1, 10],
          //   [2, 23],
          //   [3, 17],
          //   [4, 18],
          //   [5, 9],
          //   [6, 11],
          //   [7, 27],
          //   [8, 33],
          //   [9, 40],
          //   [10, 32],
          //   [11, 35]
          // ]}
          options={{
            backgroundColor: "#e0fffd",
            legend: { position: "top" },
            title: "Boat & Device Battery",
            is3d: true,
            maximize: true,
            chartArea: { width: "85%", height: "60%" },
            focusTarget: "category",
            // pointsVisible: true
            // hAxis: {
            //   title: "test"
            // },
            vAxis: {
              title: "Volts"
            }
          }}
       //   rootProps={{ "data-testid": "2" }}
        />
      </div>
    );
  }
}
