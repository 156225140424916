import React, { Component } from "react";
import {
  Table,
  Segment,
  Header,
    Grid,
  Label,
  Icon,
  Button,
  Dimmer,
  Loader
} from "semantic-ui-react";
import {
  selectView,
  apiGetMethod,
   convertDBDatetoformatWithTime,
  dateTo_YYYYMMDD_format,
  getatestEventsTableData,
  convertDBDatetoformat,
  dateFormatDataLog
} from "../modules/data";
export default class LatestEventsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latestEventsdata: [],
      latestEventsdataPageLoaderActive: true,
      selectedDeviceId: "",
      selectedBoatDetails: {},
      selectedDate: "",
      nextDayButtonEnable: false
      };
      this.refreshEventTablePage = this.refreshEventTablePage.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        var update = {};
        var boat = {};
        if (props.boatData !== state.selectedBoatDetails) {
            boat = props.boatData
            var last_seen_time = null;
            var last_seen_message = null;
            var last_updated_at_show = null;
            var update_segment_color = '#20B2AA'
            if (boat.timestamp_diff != null) {
                let temp_timestamp_diff = boat.timestamp_diff;
                if (temp_timestamp_diff >= 518400) {
                    last_seen_time = (temp_timestamp_diff / 518400).toFixed(0)
                    last_seen_message = 'Year(s) ago'
                    last_updated_at_show = null;
                } else if (temp_timestamp_diff >= 43200) {
                    last_seen_time = (temp_timestamp_diff / 43200).toFixed(0)
                    last_seen_message = 'Month(s) ago'
                    last_updated_at_show = null;
                }
                else if (temp_timestamp_diff >= 1440) {
                    last_seen_time = (temp_timestamp_diff / 1440).toFixed(0)
                    last_seen_message = 'Day(s) ago'
                    last_updated_at_show = null;
                } else if (temp_timestamp_diff >= 60) {
                    last_seen_time = (temp_timestamp_diff / 60).toFixed(0)
                    last_seen_message = 'hour(s) ago'
                    last_updated_at_show = null
                } else if (0 < temp_timestamp_diff && temp_timestamp_diff < 60) {
                    last_seen_time = temp_timestamp_diff
                    last_seen_message = 'minutes ago'
                    last_updated_at_show = null
                } else {
                    console.log("222222222222", temp_timestamp_diff);
                    last_seen_time = 'few'
                    last_seen_message = 'seconds ago'
                    last_updated_at_show = null
                }

                var send_alarm_on = false
                if ((boat.stype != null && boat.stype.length > 0 && boat.stype[0] != null) && (boat.stype[0].value.alarm_1 == 1 ||
                    boat.stype[0].value.bilge_alarm == 1 ||
                    boat.stype[0].value.reboot == 1 ||
                    boat.stype[0].value.relay_status == 1 ||
                    boat.stype[0].value.shore_power == 1)) {
                    send_alarm_on = true
                }

                if (temp_timestamp_diff > 150) {
                    update_segment_color = "#D3D3D3" 
                } else if (send_alarm_on == true) {
                    update_segment_color = "#ffcccc"
                }

                
            }

            boat.last_seen_time = last_seen_time
            boat.last_seen_message = last_seen_message
            boat.last_updated_at_show = last_updated_at_show
            boat.update_segment_color = update_segment_color
            update.selectedBoatDetails = boat
        }
        //console.log("update", update);
        return update;
    }
  async componentDidMount() {
    // if (this.props.length != 0) {
    //   getatestEventsTableData(this.props.boatData);
    // }
    console.log("this.prop latest events page", this.props.boatData);

    try {
      let latestEventsdata = await this.get_LatestEventsdata(
        this.props.boatData.device_id
        // this.props.boatData[0].device_id
      );
      // console.log("latestEventsdata", latestEventsdata);
      // this.setState({
      //   latestEventsdata: latestEventsdata,
      //   latestEventsdataPageLoaderActive: false,
      //   selectedDeviceId: this.props.boatData[0].device_id,
      //   selectedDate: latestEventsdata[0].last_updated_at
      // });
    } catch (err) {}
  }
    get_LatestEventsdata = async (device_id, selected_date) => {
        console.log("selected_date", selected_date);
    // console.log("device id is", device_id, " and date is ", selected_date);
    let selected_date_Formatted = new Date(selected_date); 
    let nextDayButtonEnable = false;
    if (
      new Date().toDateString() === selected_date_Formatted.toDateString() ||
      selected_date == null
    )
      nextDayButtonEnable = true;
    else nextDayButtonEnable = false;

    const path = `/boat/get_latest_device_records`;
    let queryStringParameters = {
      device_id: device_id,
      selected_date: selected_date
    };
    let latestEventsdata = await apiGetMethod(path, queryStringParameters);
    console.log("latestEventsdata", latestEventsdata);
    this.setState({
      latestEventsdata: latestEventsdata,
      latestEventsdataPageLoaderActive: false,
      selectedDeviceId: device_id,
      nextDayButtonEnable: nextDayButtonEnable,
      selectedDate:
        latestEventsdata.length == 0
          ? selected_date
          : latestEventsdata[0].last_updated_at
    });
  };
  backButtonHandler = () => {
    this.props.callBack(selectView.BoatDetailedOverview);
  };
  componentDidUpdate(prevProps, prevState) {
    // console.log(
    //   "prevProps is ",
    //   prevProps,
    //   "  prevStateis ",
    //   prevState,
    //   "props ",
    //   this.props
    // );
    // getatestEventsTableData(this.props.boatData);
    }

    refreshEventTablePage() {
        this.props.refreshEventTablePage()
    }; 

  previousDateSelector = async () => {
    this.setState({ latestEventsdataPageLoaderActive: true });
    let previousDate = new Date(this.state.selectedDate);
    previousDate.setDate(previousDate.getDate() - 1);
      let previousDateFormatted = await dateTo_YYYYMMDD_format(previousDate);
      console.log('previousDateFormatted',previousDateFormatted)
    this.get_LatestEventsdata(
      this.state.selectedDeviceId,
      previousDateFormatted.toString()
    );
  };
  nextDateSelector = async () => {
    this.setState({ latestEventsdataPageLoaderActive: true });
    let nextDate = new Date(this.state.selectedDate);
    nextDate.setDate(nextDate.getDate() + 1);
    let nextDateFormatted = await dateTo_YYYYMMDD_format(nextDate);
    this.get_LatestEventsdata(
      this.state.selectedDeviceId,
      nextDateFormatted.toString()
    );
  };

    render() {
        const {
            selectedBoatDetails
        } = this.state;
    return (
      <React.Fragment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
                        <Segment style={{ backgroundColor: selectedBoatDetails.update_segment_color }}  inverted>
                            <Label style={{ backgroundColor: selectedBoatDetails.update_segment_color }} >
                            <Header as='h4'> <Header.Content>
                                {this.props.boatData.device_name}
                                <Header.Subheader>{this.props.boatData.device_eid}</Header.Subheader>
                                <Header.Subheader>Updated:{" "}{this.state.selectedBoatDetails.last_updated_at_show != null ? this.state.selectedBoatDetails.last_updated_at_show : ''} {this.state.selectedBoatDetails.last_seen_time != null
                                    ? + this.state.selectedBoatDetails.last_seen_time + ' ' + this.state.selectedBoatDetails.last_seen_message
                                    : ""}</Header.Subheader>
                                </Header.Content> </Header>
                                </Label>
                {/* <Header>{this.props.boatData[0].tags.device_name}</Header> */}
                <Button
                  floated="right"
                                circular
                                size='mini'
                  icon="arrow left"
                  color="blue"
                  onClick={this.backButtonHandler}
                            />
                            <Button
                                //attached='left'
                                floated="right"
                                circular
                                icon="refresh"
                                color="blue"
                                size='mini'
                                onClick={this.refreshEventTablePage}
                            />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Header>Latest Events</Header>
        <Button.Group basic color="blue">
          <Button onClick={this.previousDateSelector}>
            {" "}
            <Icon name="arrow circle left" />
            Previous Day
          </Button>
          <Button
            onClick={this.nextDateSelector}
            disabled={this.state.nextDayButtonEnable}
          >
            Next Day <Icon name="arrow circle right" />
          </Button>
        </Button.Group>

        <Segment>
          <Dimmer active={this.state.latestEventsdataPageLoaderActive}>
            <Loader size="medium">Loading</Loader>
          </Dimmer>
          <Table compact unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Shore power</Table.HeaderCell>
                <Table.HeaderCell>Solar power</Table.HeaderCell>
                <Table.HeaderCell>Alarm In</Table.HeaderCell>
                <Table.HeaderCell>Bilge alarm</Table.HeaderCell>
                <Table.HeaderCell>Global alarm</Table.HeaderCell>
                <Table.HeaderCell>Relay status</Table.HeaderCell>
                <Table.HeaderCell>GPS</Table.HeaderCell>
                <Table.HeaderCell>Reboot</Table.HeaderCell>
                <Table.HeaderCell>Internal battery</Table.HeaderCell>
                <Table.HeaderCell>Boat battery</Table.HeaderCell>
                <Table.HeaderCell>Bilge pump</Table.HeaderCell>
                <Table.HeaderCell>Latitude</Table.HeaderCell>
                <Table.HeaderCell>Longitude</Table.HeaderCell>
                <Table.HeaderCell>Hdop</Table.HeaderCell>
                <Table.HeaderCell>Drift</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {this.state.latestEventsdata.map(boat => (
                <Table.Row key={boat.measure_id}>
                  <Table.Cell>
                   {dateFormatDataLog( boat.timestmp_format)}
                  </Table.Cell>
                  <Table.Cell>{boat.stype[0].value.shore_power}</Table.Cell>
                  <Table.Cell>{boat.stype[0].value.solar_power}</Table.Cell>
                  <Table.Cell>{boat.stype[0].value.alarm_1}</Table.Cell>
                  <Table.Cell>{boat.stype[0].value.bilge_alarm}</Table.Cell>
                  <Table.Cell>{boat.stype[0].value.global_alarm}</Table.Cell>
                  <Table.Cell>{boat.stype[0].value.relay_status}</Table.Cell>
                  <Table.Cell>{boat.stype[0].value.gps} </Table.Cell>
                  <Table.Cell>{boat.stype[0].value.reboot}</Table.Cell>
                  <Table.Cell>{boat.stype[1].value / 1000}V</Table.Cell>
                  <Table.Cell>{boat.stype[2].value / 1000}V</Table.Cell>
                  <Table.Cell>{boat.stype[3].value} s</Table.Cell>

                  <Table.Cell>
                    {boat.stype[4] != null
                      ? boat.stype[4].value.latitude.toFixed(4)
                      : ""}
                  </Table.Cell>
                  <Table.Cell>
                    {boat.stype[4] != null
                      ? boat.stype[4].value.longitude.toFixed(4)
                      : ""}
                  </Table.Cell>
                  <Table.Cell>
                    {boat.stype[4] != null ? boat.stype[4].value.hdop : ""}
                  </Table.Cell>
                  <Table.Cell>
                    {boat.stype[4] != null ? boat.stype[4].value.drift : ""}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>

        <Button.Group basic color="blue">
          <Button onClick={this.previousDateSelector}>
            <Icon name="arrow circle left" />
            Previous Day
          </Button>
          <Button
            onClick={this.nextDateSelector}
            disabled={this.state.nextDayButtonEnable}
          >
            Next Day
            <Icon name="arrow circle right" />
          </Button>
        </Button.Group>
      </React.Fragment>
    );
  }
}
