export default {
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_CO2EPTNHC",
        APP_CLIENT_ID: "189e59pkod0v9g18cla1kg2ir",
        IDENTITY_POOL_ID: "ap-southeast-2:9c94ab1e-0eca-47ac-ad98-198e477eb5ee"
       },
        apiGateway: {
            REGION: "ap-southeast-2",
            URL:   "https://whbqctuxlc.execute-api.ap-southeast-2.amazonaws.com/prod" 
        }
}

 


