import React, { Component } from "react";
import {
    Header,
    Segment,
    Grid,
    List,
    Menu,
    Image,
    Icon,
    Button,
    Label
} from "semantic-ui-react";
import { selectView } from "../modules/data";

export default class BoatOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      batteryHover: false,
      bilgeHover: false,
      selectedBoatDetails:{},
      shoreHover: false,
      anchorHover: false,
      relayAlarmHover: false,
      relaySwitchHover: false,
      windyHover: false,
      coatGuardHover: false,
      smallScreen: false
      };

      this.refreshOverViewPage = this.refreshOverViewPage.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        var update = {};
        var boat = {};
        if (props.selectedBoat !== state.selectedBoatDetails){
            boat = props.selectedBoat
            var last_seen_time = null;
            var last_seen_message = null;
            var last_updated_at_show = null;
            var update_segment_color = '#20B2AA'
            if (boat.timestamp_diff != null ) {
                let temp_timestamp_diff = boat.timestamp_diff;
                if (temp_timestamp_diff >= 518400) {
                    last_seen_time = (temp_timestamp_diff / 518400).toFixed(0)
                    last_seen_message = 'Year(s) ago'
                    last_updated_at_show = null;
                } else if (temp_timestamp_diff >= 43200) {
                    last_seen_time = (temp_timestamp_diff / 43200).toFixed(0)
                    last_seen_message = 'Month(s) ago'
                    last_updated_at_show = null;
                }
                else if (temp_timestamp_diff >= 1440) {
                    last_seen_time = (temp_timestamp_diff / 1440).toFixed(0)
                    last_seen_message = 'Day(s) ago'
                    last_updated_at_show = null;
                } else if (temp_timestamp_diff >= 60) {
                    last_seen_time = (temp_timestamp_diff / 60).toFixed(0)
                    last_seen_message = 'hour(s) ago'
                    last_updated_at_show = null
                    
                } else if (0 < temp_timestamp_diff && temp_timestamp_diff < 60) {
                    last_seen_time = temp_timestamp_diff
                    last_seen_message = 'minutes ago'
                    last_updated_at_show = null
                }else {
                    console.log("222222222222", temp_timestamp_diff);
                    last_seen_time = 'few'
                    last_seen_message = 'seconds ago'
                    last_updated_at_show = null
                }

               
            }
            var send_alarm_on=false
            if ((boat.stype != null && boat.stype.length > 0 && boat.stype[0] != null) &&  (boat.stype[0].value.alarm_1 == 1 ||
                boat.stype[0].value.bilge_alarm == 1 ||
                boat.stype[0].value.reboot == 1 ||
                boat.stype[0].value.relay_status == 1 ||
                boat.stype[0].value.shore_power == 1)) {
                send_alarm_on = true
            }
          
            if (boat.timestamp_diff != null && boat.timestamp_diff > 150) {
                update_segment_color = "#D3D3D3"
                console.log('11111111111', update_segment_color)
            }else  if (send_alarm_on==true) {
                update_segment_color = "#ffcccc" 
                console.log('222222222222', update_segment_color)
            }

            boat.last_seen_time = last_seen_time
            boat.last_seen_message = last_seen_message
            boat.last_updated_at_show = last_updated_at_show
            boat.update_segment_color = update_segment_color
            update.selectedBoatDetails = boat
            }
        
        //console.log("update", update);
        return update;
    }

  componentDidMount() {
    if (window.innerWidth <= 1050) {
      this.setState({ smallScreen: true });
    }
  }

    callbackFunction = e => {
        //  console.log("callbackfunction", this.props);
        this.props.callBack(selectView.BoatDetailedOverview);
    };

    refreshOverViewPage() {
        this.props.refreshOverViewPage()
    };  
  openLink = link => {
    //  console.log("link to open", link);
    window.open(link);
    //window.open(link, "_blank");
  };
  backButtonHandler = () => {
    this.props.callBack(selectView.BoatList);
  };
  // toggleBatteryHover = () => {
  //   this.setState({ batteryHover: !this.state.batteryHover });
  // };
  // toggleBilgeHover = () => {
  //   this.setState({ bilgeHover: !this.state.bilgeHover });
  // };
  // toggleShoreHover = () => {
  //   this.setState({ shoreHover: !this.state.shoreHover });
  // };
  // toggleRelayAlarmHover = () => {
  //   this.setState({ relayAlarmHover: !this.state.relayAlarmHover });
  // };
  // toggleRelaySwitchHover = () => {
  //   this.setState({ relaySwitchHover: !this.state.relaySwitchHover });
  // };
  // toggleWindyHover = () => {
  //   this.setState({ windyHover: !this.state.windyHover });
  // };
  // toggleCGHover = () => {
  //   this.setState({ coatGuardHover: !this.state.coatGuardHover });
  // };
  // toggleAnchorHover = () => {
  //   this.setState({ anchorHover: !this.state.anchorHover });
  // };
    render() {
       
    const selectedBoatDetails = this.state.selectedBoatDetails
        //console.log("selectedBoatDetails", selectedBoatDetails);
    var linkStyle = {
      boxShadow: "1px 3px 10px #929693",
      backgroundColor: "#e0fffd"
    };
    var linkStyleIn = { boxShadow: "1px 3px 20px #2E929E" };
    return (
      <React.Fragment>
        {/* <Segment basic> */}
        {/* <BoatDetailedOverview/> */}
        <Grid>
          <Grid.Row>
            <Grid.Column>
                        <Segment style={
                            { backgroundColor: selectedBoatDetails.update_segment_color }
                        } inverted  >
                            <Label style={{ backgroundColor: selectedBoatDetails.update_segment_color }} >
                            <Header as='h4'> <Header.Content>
                                {this.props.selectedBoat.device_name}
                                <Header.Subheader>{this.props.selectedBoat.device_eid}</Header.Subheader>
                                <Header.Subheader>Updated:{" "}{this.state.selectedBoatDetails.last_updated_at_show != null ? this.state.selectedBoatDetails.last_updated_at_show : ''} {this.state.selectedBoatDetails.last_seen_time != null
                                    ? this.state.selectedBoatDetails.last_seen_time + ' ' + this.state.selectedBoatDetails.last_seen_message
                                    : ""}</Header.Subheader>
                                </Header.Content> </Header> </Label>
                            
                <Button
                  floated="right"
                  circular
                  size='mini'
                  icon="arrow left"
                  color="blue"
                  onClick={this.backButtonHandler}
                            />

                            <Button
                                //attached='left'
                                floated="right"
                                circular
                                icon="refresh"
                                color="blue"
                                size='mini'
                                onClick={this.refreshOverViewPage}
                            />
              </Segment>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={2}>
            <Grid.Column>
              <a href="#" onClick={this.callbackFunction}>
                <Segment.Group
                  style={this.state.batteryHover ? linkStyleIn : linkStyle}
                  onMouseEnter={()=>this.setState({ batteryHover: true })}
                  onMouseLeave={()=>this.setState({ batteryHover: false })}
                >
                  <Segment
                    style={
                      this.props.selectedBoat.stype[2].value / 1000 < 11
                        ? { backgroundColor: "#ffdede" }
                        : { backgroundColor: "#e0fffd" }
                    }
                  >
                    <Header as={this.state.smallScreen ? "h6" : "h5"}>
                      <Image src="/battery.gif" alt="" />
                      Boat Battery
                    </Header>
                                    </Segment>
                                    <Segment
                                        style={
                                            this.props.selectedBoat.stype[2].value / 1000 < 11
                                                ? { backgroundColor: "#ffc5c5" }
                                                : { backgroundColor: "#c9e5e3" }
                                        }
                                        color="grey"
                                        tertiary

                                    // color={
                                    //   this.props.selectedBoat.stype[2].value / 1000 < 11
                                    //     ? "red"
                                    //     : "grey"
                                    // }
                                    // inverted={
                                    //   this.props.selectedBoat.stype[2].value / 1000 < 11
                                    //     ? true
                                    //     : false
                                    // }
                                    >
                                        <Label
                                            attached="bottom right"
                                            circular
                                            color={
                                                this.props.selectedBoat.stype[2].value / 1000 < 11
                                                    ? "red"
                                                    : "green"
                                            }
                                            basic
                                        >
                                            {" "}
                                            <Icon.Group size="large">
                                                <Icon size="big" name="circle outline" />
                                                <Icon
                                                    name={
                                                        this.props.selectedBoat.stype[2].value / 1000 > 11
                                                            ? "thumbs up"
                                                            : "hand paper"
                                                    }
                                                />
                                            </Icon.Group>
                                        </Label>
                                        {this.props.selectedBoat.stype[2].value / 1000} V
                  </Segment>
                </Segment.Group>
              </a>
            </Grid.Column>
            <Grid.Column>
              {" "}
              <a href="#" onClick={this.callbackFunction}>
                <Segment.Group
                  style={this.state.bilgeHover ? linkStyleIn : linkStyle}
                  onMouseEnter={()=>this.setState({ bilgeHover: true })}
                  onMouseLeave={()=>this.setState({ bilgeHover: false })}
                  // onMouseEnter={this.toggleBilgeHover}
                  // onMouseLeave={this.toggleBilgeHover}
                >
                  <Segment
                    style={
                      this.props.selectedBoat.stype[3].value == 0
                        ?{ backgroundColor: "#e0fffd" }
                        :  { backgroundColor: "#ffdede" }
                    }
                  >
                    <Header as={this.state.smallScreen ? "h6" : "h5"}>
                      <Image src="/bilge-alarm.gif" alt="" />
                      Bilge Alarm
                    </Header>
                                    </Segment>
                                    <Segment
                                        style={
                                            this.props.selectedBoat.stype[3].value == 0
                                                ?  { backgroundColor: "#c9e5e3" }
                                                :{ backgroundColor: "#ffc5c5" }
                                        }
                                        color="grey"
                                        tertiary
                                    // color={
                                    // //   this.props.selectedBoat.stype[3].value == 1
                                    // //     ? "red"
                                    // //     : "grey"
                                    // // }
                                    // // inverted={
                                    // //   this.props.selectedBoat.stype[3].value == 1 ? true : false
                                    // // }
                                    >
                                        <Label
                                            attached="bottom right"
                                            circular
                                            color={
                                                this.props.selectedBoat.stype[3].value == 0
                                                    ? "green"
                                                    : "red"
                                            }
                                            basic
                                        >
                                            <Icon.Group size="large">
                                                <Icon size="big" name="circle outline" />
                                                <Icon
                                                    name={
                                                        this.props.selectedBoat.stype[3].value == 0
                                                            ? "thumbs up"
                                                            : "hand paper"
                                                    }
                                                />
                                            </Icon.Group>
                                        </Label>
                                        {this.props.selectedBoat.stype == null
                                            ? "Not Available"
                                            : this.props.selectedBoat.stype[3].value == 0
                                                ? "Off"
                                                : "On"}
                                    </Segment>{" "}
                                </Segment.Group>
                            </a>
                        </Grid.Column>
                    </Grid.Row>

          <Grid.Row columns={2}>
            <Grid.Column>
              <a href="#" onClick={this.callbackFunction}>
                <Segment.Group
                  style={this.state.shoreHover ? linkStyleIn : linkStyle}
                  onMouseEnter={()=>this.setState({ shoreHover: true })}
                  onMouseLeave={()=>this.setState({ shoreHover: false })}
                >
                  <Segment
                    style={
                      this.props.selectedBoat.stype == null ||
                      this.props.selectedBoat.stype[0].value.shore_power == 1
                        ? { backgroundColor: "#ffdede" }
                        : { backgroundColor: "#e0fffd" }
                    }
                  >
                    <Header as={this.state.smallScreen ? "h6" : "h5"}>
                      <Image src="/shore-power.gif" alt="" />
                      Shore Power
                    </Header>
                  </Segment>
                  <Segment
                    style={
                      this.props.selectedBoat.stype == null ||
                      this.props.selectedBoat.stype[0].value.shore_power == 1
                        ? { backgroundColor: "#ffc5c5" }
                        : { backgroundColor: "#c9e5e3" }
                    }
                    color="grey"
                    tertiary
                    //  color= {this.props.selectedBoat.stype[0].value.shore_power == 1? 'red': 'grey'}
                    //  inverted={this.props.selectedBoat.stype[0].value.shore_power == 1? true: false}
                  >
                    <Label color="green" basic attached="bottom right" circular>
                      <Icon.Group size="large">
                        <Icon size="big" name="circle outline" />
                        <Icon name="thumbs up" />
                      </Icon.Group>
                    </Label>
                    {this.props.selectedBoat.stype == null ||
                    this.props.selectedBoat.stype[0].value.shore_power == 1
                      ? "Off"
                      : "On"}
                  </Segment>
                </Segment.Group>
              </a>
            </Grid.Column>
            <Grid.Column>
              <a href="#" onClick={this.callbackFunction}>
                <Segment.Group
                  style={this.state.anchorHover ? linkStyleIn : linkStyle}
                  onMouseEnter={()=>this.setState({ anchorHover: true})}
                  onMouseLeave={()=>this.setState({ anchorHover: false })}
                >
                  <Segment style={{ backgroundColor: "#e0fffd" }}>
                    <Header as={this.state.smallScreen ? "h6" : "h5"}>
                      <Image src="/anchor-drift.gif" alt="" />
                      Anchor Drift
                    </Header>
                                    </Segment>
                                    <Segment
                                        style={{ backgroundColor: "#c9e5e3" }}
                                        color="grey"
                                        tertiary
                                    //      color= {this.props.selectedBoat.stype[0].value.gps == 1? 'red': 'grey'}
                                    //  inverted={this.props.selectedBoat.stype[0].value.gps == 1? true: false}
                                    >
                                        <Label color="green" basic attached="bottom right" circular>
                                            <Icon.Group size="large">
                                                <Icon size="big" name="circle outline" />
                                                <Icon name="thumbs up" />
                                            </Icon.Group>
                                        </Label>
                                        {this.props.selectedBoat.stype == null ||
                                            this.props.selectedBoat.stype[0].value.gps == 0
                                            ? "Off"
                                            : "On"}
                                    </Segment>
                                </Segment.Group>
                            </a>
                        </Grid.Column>
                    </Grid.Row>

          <Grid.Row columns={2}>
            <Grid.Column>
              <a href="#" onClick={this.callbackFunction}>
                <Segment.Group
                  style={this.state.relayAlarmHover ? linkStyleIn : linkStyle}
                  onMouseEnter={()=>this.setState({ relayAlarmHover: true })}
                  onMouseLeave={()=>this.setState({ relayAlarmHover: false })}
                >
                  <Segment
                    style={
                      this.props.selectedBoat.stype[0].value.alarm_1 == 1
                        ? { backgroundColor: "#ffdede" }
                        : { backgroundColor: "#e0fffd" }
                    }
                  >
                    <Header as={this.state.smallScreen ? "h6" : "h5"}>
                      <Image src="/relay-alarm.png" alt="" />
                      Relay Alarm
                    </Header>
                  </Segment>
                  <Segment
                    style={
                      this.props.selectedBoat.stype[0].value.alarm_1 == 1
                        ? { backgroundColor: "#ffc5c5" }
                        : { backgroundColor: "#c9e5e3" }
                    }
                    color="grey"
                    tertiary
                    // color={
                    //   this.props.selectedBoat.stype[0].value.alarm_1 == 1
                    //     ? "red"
                    //     : "grey"
                    // }
                    // inverted={
                    //   this.props.selectedBoat.stype[0].value.alarm_1 == 1
                    //     ? true
                    //     : false
                    // }
                  >
                    <Label
                      color={
                        this.props.selectedBoat.stype[0].value.alarm_1 == 1
                          ? "red"
                          : "green"
                      }
                      basic
                      attached="bottom right"
                      circular
                    >
                      {" "}
                      <Icon.Group size="large">
                        <Icon size="big" name="circle outline" />
                        <Icon
                          name={
                            this.props.selectedBoat.stype[0].value.alarm_1 == 0
                              ? "thumbs up"
                              : "hand paper"
                          }
                        />
                      </Icon.Group>
                    </Label>{" "}
                    {this.props.selectedBoat.stype == null ||
                    this.props.selectedBoat.stype[0].value.alarm_1 == 0
                      ? "Off"
                      : "On"}
                  </Segment>
                </Segment.Group>
              </a>
            </Grid.Column>
            <Grid.Column>
              <a href="#" onClick={this.callbackFunction}>
                <Segment.Group
                  style={this.state.relaySwitchHover ? linkStyleIn : linkStyle}
                  onMouseEnter={()=>this.setState({ relaySwitchHover: true })}
                  onMouseLeave={()=>this.setState({ relaySwitchHover: false })}
                >
                  <Segment
                    style={
                      this.props.selectedBoat.stype[0].value.relay_status == 1
                        ? { backgroundColor: "#ffdede" }
                        : { backgroundColor: "#e0fffd" }
                    }
                  >
                    <Header as={this.state.smallScreen ? "h6" : "h5"}>
                      <Image src="/relay-switch.png" alt="" />
                      Relay Switch
                    </Header>
                                    </Segment>
                                    <Segment
                                        style={
                                            this.props.selectedBoat.stype[0].value.relay_status == 1
                                                ? { backgroundColor: "#ffc5c5" }
                                                : { backgroundColor: "#c9e5e3" }
                                        }
                                        color="grey"
                                        tertiary
                                    // color={
                                    //   this.props.selectedBoat.stype[0].value.relay_status == 1
                                    //     ? "red"
                                    //     : "grey"
                                    // }
                                    // inverted={
                                    //   this.props.selectedBoat.stype[0].value.relay_status == 0
                                    //     ? false
                                    //     : true
                                    // }
                                    >
                                        <Label
                                            color={
                                                this.props.selectedBoat.stype[0].value.relay_status == 1
                                                    ? "red"
                                                    : "green"
                                            }
                                            basic
                                            attached="bottom right"
                                            circular
                                        >
                                            {" "}
                                            <Icon.Group size="large">
                                                <Icon size="big" name="circle outline" />
                                                <Icon
                                                    name={
                                                        this.props.selectedBoat.stype[0].value
                                                            .relay_status == 0
                                                            ? "thumbs up"
                                                            : "hand paper"
                                                    }
                                                />
                                            </Icon.Group>
                                        </Label>
                                        {this.props.selectedBoat.stype == null ||
                                            this.props.selectedBoat.stype[0].value.relay_status == 0
                                            ? "Off"
                                            : "On"}
                                    </Segment>
                                </Segment.Group>
                            </a>
                        </Grid.Column>
                    </Grid.Row>

          <Grid.Row columns={2}>
            <Grid.Column>
              <a
                href="https://www.windy.com/?-42.212,175.583,6"
                // onClick={() =>
                //   this.openLink("https://www.windy.com/?-42.212,175.583,6")
                // }
                target="_blank"
              >
                <Segment.Group
                  style={this.state.windyHover ? linkStyleIn : linkStyle}
                  onMouseEnter={()=>this.setState({ windyHover: true })}
                  onMouseLeave={()=>this.setState({ windyHover: false })}
                >
                  <Segment style={{ backgroundColor: "#e0fffd" }}>
                    <Header as={this.state.smallScreen ? "h6" : "h5"}>
                      <Image src="/windy.png" alt="" />
                      Windy
                    </Header>
                                    </Segment>
                                    <Segment
                                        style={{ backgroundColor: "#c9e5e3" }}
                                        color="grey"
                                        tertiary
                                    >
                                        Weather
                  </Segment>
                </Segment.Group>
              </a>
            </Grid.Column>
            <Grid.Column>
              <a
                href="https://www.coastguard.nz/boating-safely/"
                // onClick={() =>
                //   this.openLink("https://www.coastguard.nz/boating-safely/")
                // }
                target="_blank"
              > 
                <Segment.Group
                  style={this.state.coatGuardHover ? linkStyleIn : linkStyle}
                  onMouseEnter={()=>this.setState({ coatGuardHover: true })}
                  onMouseLeave={()=>this.setState({ coatGuardHover: false })}
                >
                  <Segment style={{ backgroundColor: "#e0fffd" }}>
                    <Header as={this.state.smallScreen ? "h6" : "h5"}>
                      <Image src="/lighthouse.png" alt="" />
                      CoastGuard
                    </Header>
                                    </Segment>
                                    <Segment
                                        style={{ backgroundColor: "#c9e5e3" }}
                                        color="grey"
                                        tertiary
                                    >
                                        CoastGuard
                  </Segment>
                                </Segment.Group>
                            </a>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {/* </Segment> */}
            </React.Fragment>
        );
    }
}
