import React, { Component } from "react";
import {
    Card,
    Image,
    Header,
    Segment,
    Loader,
    Responsive,
    List,
    TextArea
} from "semantic-ui-react";
// import Boatlist from "./Boatlist";
import {
    selectView,
    getDateFormat,
    convertDBDatetoformat
} from "../modules/data";

export default class Fleetlist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fleetlist: [],
            headerTest: []
        };
    }

    //static getDerivedStateFromProps(props, state) {
    //    var update = {};
    //  console.log("nextProps", props);
    //  console.log("prevState", state);
    //    if (props.fleetlist !== state.fleetlist) {
    //         this.props.fleetlist
    //    }
    //    return update;
    //}
    componentDidMount() {
        console.log('b34343434bbb', this.props);
        var myHeaders = new Headers(); // Currently empty
        let headerTest = myHeaders.get('Not-Set')
        // console.log("headerTest", headerTest);
        var myRequest = new Request("flowers.jpg");
        var myHeaders = myRequest.headers;
        console.log("Processing request for: ", myHeaders.keys);
        this.setState({ fleetlist: this.props.fleetlist, headerTest: myHeaders });
    }

    callbackFunction = e => {
        //console.log("callbackfunction", e);
        this.props.callBack(selectView.BoatList, e);
    };

    commonSortingHandler(list, key) {
        function compare(a, b) {
            a = a[key];
            b = b[key];
            var type =
                typeof a === "string" || typeof b === "string" ? "string" : "number";
            var result;
            if (type === "string") result = a.localeCompare(b);
            else result = a - b;
            return result;
        }
        return list.sort(compare);
    }



    render() {
        console.log('bbbbbbbbbbbbbb', this.props);
        //const { fleetlist } = this.state
        var fleet_list = this.props.fleetlist
        var key = 'asset_name'
        function commonSortingHandler(fleet_list, key) {
            function compare(a, b) {
                a = a[key];
                b = b[key];
                var type =
                    typeof a === "string" || typeof b === "string" ? "string" : "number";
                var result;
                if (type === "string") result = a.localeCompare(b);
                else result = a - b;
                return result;
            }
            console.log('88888888888', fleet_list.sort(compare));
            return fleet_list.sort(compare);
        }

        fleet_list = commonSortingHandler(fleet_list, key)


        return (
            <React.Fragment>
                <Header>Fleet List </Header>
                {/* <Label>
    Dogs 
  </Label>
  <Label>
    Dogs 
  </Label>
  <Label>
    Dogs 
  </Label> */}
                <Responsive maxWidth={414} >
                    <Card.Group itemsPerRow={2}>
                        <Loader
                            active={this.props.fleetListPageLoaderActive}
                            inline="centered"
                        />
                        {fleet_list.map(fleet => (
                            <Card
                                fluid={true}
                                href="#"
                                centered={true}
                                onClick={() => this.callbackFunction(fleet)}
                                key={fleet.asset_id}
                            >
                                <Card.Content>
                                    <Image centered={true} src="asset_mobile.png" wrapped ui={false} />
                                </Card.Content>

                                <Segment size='mini' color="teal" inverted>
                                    <List>
                                        <List.Item><b>{fleet.asset_name}</b></List.Item>
                                        <List.Item>Added: {convertDBDatetoformat(fleet.created_at)}</List.Item>
                                    </List>
                                </Segment>
                            </Card>

                        ))}
                    </Card.Group>
                </Responsive>

                <Responsive minWidth={415} >
                    <Card.Group>
                        <Loader
                            active={this.props.fleetListPageLoaderActive}
                            inline="centered"
                        />
                        {this.props.fleetlist.map(fleet => (
                            <Card
                                href="#"
                                onClick={() => this.callbackFunction(fleet)}
                                key={fleet.asset_id}
                            >
                                <Card.Content>
                                    <Image src="asset.png" wrapped ui={false} />
                                </Card.Content>
                                <Segment color="teal" inverted>
                                    <Header>{fleet.asset_name}</Header>
                                    <Header.Subheader>
                                        Added: {convertDBDatetoformat(fleet.created_at)}
                                    </Header.Subheader>
                                </Segment>
                            </Card>

                        ))}
                    </Card.Group>
                </Responsive>
                {/* <Segment>  <Boatlist/></Segment> */}
            </React.Fragment>
        );
    }
}
