import React, { Component } from "react";
import {
  Menu,
  Segment,
  Responsive,
  Icon,
  Sidebar,
  Label,
  Image,
  Header
} from "semantic-ui-react";
import Auth from "@aws-amplify/auth";
import LoginForm from "./components/LoginForm";
import Main from "./components/Main";
import { apiPostMethod } from "../src/modules/data";
const uuid = require("uuid4");

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      // userName: this.props.userName,
      headers: "",
      log_out: false,
      verificationCode: "",
      userid: '',
      // userid: this.props.userid,
      visible: false,
      fleet_location: [],
      user_payload: '',
      // user_payload: this.props.user_payload,
      homePageSelected: false,
      activeItem: "home",
      fleetViewSelected: false,
      userLastLocationMap: {},
      isAuthenticated: false,
      // isAuthenticated: this.props.isAuthenticated,
      IsMobile: false
      // IsMobile: this.props.IsMobile
    };
    this.userHasAuthenticated = this.userHasAuthenticated.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleSidebarHide = this.handleSidebarHide.bind(this);
    this.gotoHomePage = this.gotoHomePage.bind(this);
    this.gotoBoatSecureWebSite = this.gotoBoatSecureWebSite.bind(this);
    this.emailUs = this.emailUs.bind(this);
    this.handleClickSidebarLogo = this.handleClickSidebarLogo.bind(this);
    this.selectFleetView = this.selectFleetView.bind(this);
    this.changeHomePageSelected = this.changeHomePageSelected.bind(this);
  }

  static async getDerivedStateFromProps(nextProps, prevState) {
    console.log("nextProps", nextProps);
    console.log("prevState", prevState);
    var updateStateObj = {};
    //try {
    //    var user_sub;
    //    await Auth.currentSession().then(data => {
    //        console.log('data', data)
    //        user_sub = data.accessToken.payload.sub;
    //        updateStateObj.userid = user_sub
    //        updateStateObj.userName = data.accessToken.payload.username
    //        updateStateObj.user_payload = data.accessToken.payload
    //        updateStateObj.isAuthenticated = true
    //    });
    //} catch (e) {
    //    if (e !== "No current user") {
    //        console.log('e', e)
    //        updateStateObj.isAuthenticated = false
    //    }
    //}

    var urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("code") != "") {
      updateStateObj.verificationCode = urlParams.get("code");
    }
    return updateStateObj;
  }

  async componentDidMount() {
    console.log("props in appjs", this.props);
    var self = this;
    const IsMobile = false;

    document.addEventListener("message", function(msg) {
      var testStringify = JSON.parse(msg.data);
      self.setState({ IsMobile: testStringify.IsMobile });
      self.IsMobile = testStringify.IsMobile;
      self.mobileAuthentication(testStringify.Idtoken, testStringify.IsMobile);
    });

    try {
      var user_sub;
      await Auth.currentSession().then(data => {
        user_sub = data.accessToken.payload.sub;
        this.setState({
          userid: user_sub,
          userName: data.accessToken.payload.username,
          user_payload: data.accessToken.payload,
          isAuthenticated: true
        });
      });
    } catch (e) {
      if (e !== "No current user") {
        this.setState({ isAuthenticated: false });
      }
    }
  }

  save_user_session_details(payload, user_sub_data) {
    let session_id = uuid();
    let updated_session_id = session_id + "-web";
    const path = `/boat/save_user_session_details`;
    let user_info = {
      session_id: updated_session_id,
      payload: payload,
      user_sub_data: user_sub_data
    };
    return apiPostMethod(path, user_info);
  }

  mobileAuthentication = (Idtoken, IsMobile) => {
    //   alert(Idtoken.payload)

    const payload = Idtoken.payload;
    //this.save_user_session_details(payload,)
    this.setState({
      userid: payload.sub,
      userName: payload.username,
      user_payload: payload,
      isAuthenticated: true,
      IsMobile: true
    });
  };
  handleSidebarHide = () => this.setState({ visible: false });
  handleClickSidebarLogo() {
    this.setState({ visible: !this.state.visible });
  }
  handleItemClick = (e, { name }) => this.setState({ activeItem: name });
  async selectFleetView() {
    await this.setState({
      visible: !this.state.visible,
      fleetViewSelected: true
    });
  }
  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  async changeHomePageSelected(e) {
    await this.setState({ homePageSelected: e });
  }
  sendLastNavigatedLocation = e => {
    var fleet_location = [];
    fleet_location = this.state.fleet_location;
    if (fleet_location.length == 0) {
      fleet_location.push(e);
    } else if (fleet_location.length > 0) {
      var isConained = 0;
      console.log("fleet_location", fleet_location);
      for (var i = 0; i < fleet_location.length; i++) {
        if (fleet_location[i].asset_id == e.asset_id) {
          isConained = 1;
          break;
        }
      }
      if (isConained == 0) {
        fleet_location.push(e);
      }

      for (var i = 0; i < fleet_location.length; i++) {
        console.log("fleet_location[i].asset_id", fleet_location[i].asset_id);
        console.log("e.asset_id", e.asset_id);
        if (fleet_location[i].asset_id == e.asset_id) {
          console.log("0000000000000000000000", e);
          fleet_location[i].user_last_location = e.user_last_location;
        }
      }
    }
    this.setState({ fleet_location: fleet_location });
    console.log("fleet_location", this.state.fleet_location);
  };
  save_user_last_location = e => {
    const path = `/boat/save_last_map_location`;
    let user_info = {
      user_id: this.state.userid,
      userLastLocationMap: e
    };
    return apiPostMethod(path, user_info);
  };

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  async handleLogout() {
    window.history.pushState({}, document.title, window.location.pathname);
    await Auth.signOut();
    this.setState({
      isAuthenticated: false,
      userName: "",
      userid: "",
      log_out: true,
      user_payload: "",
      visible: false,
      verificationCode: ""
    });
  }
  async gotoHomePage() {
    console.log("this.state.fleetViewSelected", this.state.fleetViewSelected);
    await this.setState({
      fleetViewSelected: true,
      homePageSelected: true,
      visible: false
    });
  }

  gotoBoatSecureWebSite() {
    window.open("https://www.boatsecure.net", "_blank");
  }

  emailUs() {
    window.open("mailto:info+help@boatsecure.net", "_blank");
  }
  //mail: info+help@boatsecure.net
  async userHasAuthenticated(authenticated, cognitoUser) {
    console.log("vvvvvvvvvvvvvvvvvv");
    this.setState({ isAuthenticated: authenticated, cognitoUser: cognitoUser });
    console.log("cognitoUser", cognitoUser);

    const user_id = cognitoUser.signInUserSession.accessToken.payload.sub;
    const user_payload = cognitoUser.signInUserSession.accessToken.payload;
    let user_session_paylaod = cognitoUser.signInUserSession.idToken.payload;
    let user_sub_data = {};
      user_sub_data.sub = user_session_paylaod.sub;
      user_sub_data.email = user_session_paylaod.email;
      user_sub_data.given_name = user_session_paylaod.given_name;
      user_sub_data.family_name = user_session_paylaod.family_name;
      user_sub_data.phone = user_session_paylaod.phone;
      user_sub_data.username = cognitoUser.username;
    console.log("user_sub_data", user_sub_data);
    await this.setState({
      userName: cognitoUser.username,
      userid: user_id,
      user_payload: user_payload
    });
    this.save_user_session_details(user_session_paylaod, user_sub_data);
  }
  render() {
    var verificationCode;
    var urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("code") != "" && this.state.log_out == false) {
      console.log("uuuuuuuuuuuuuu");
      verificationCode = urlParams.get("code");
    }

    const { visible } = this.state;
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: true
    };
    console.log("check state", this.state);
    return (
      <div>
        {this.state.IsMobile ? (
          <div>
            {this.state.isAuthenticated && this.state.userid != "" ? (
              <Main
                sendLastNavigatedLocation={this.sendLastNavigatedLocation}
                userid={this.state.userid}
                headers={this.state.headers}
                fleetViewSelected={this.state.fleetViewSelected}
                changeHomePageSelected={this.changeHomePageSelected}
                homePageSelected={this.state.homePageSelected}
              />
            ) : null}
          </div>
        ) : (
          <div>
            <Menu pointing size="mini" color={"blue"} inverted fluid>
              <Menu.Menu size="mini" position="left">
                <Menu.Item color={"blue"} onClick={this.selectFleetView}>
                  <img src="/boatsecure-header-logo.png" alt="" />
                </Menu.Item>
                <Menu.Item
                  size="mini"
                  name="home"
                  onClick={this.selectFleetView}
                >
                  <Label color="blue">BoatSecure</Label>
                </Menu.Item>
              </Menu.Menu>
              {this.state.isAuthenticated && this.state.userid != "" ? (
                <Menu.Menu size="mini" color="teal" position="right">
                  <Menu.Item size="mini" position="right">
                    {this.state.userName}
                  </Menu.Item>

                  <Menu.Item
                    color="grey"
                    as="a"
                    onClick={() => this.handleLogout()}
                  >
                    <Icon circular inverted color="teal" name="log out" />
                    <Responsive minWidth={768}>Log Out</Responsive>
                  </Menu.Item>
                </Menu.Menu>
              ) : null}
            </Menu>
            <Sidebar.Pushable>
              <Sidebar
                as={Menu}
                animation="overlay"
                icon="labeled"
                onHide={this.handleSidebarHide}
                vertical
                visible={visible}
                color={"blue"}
                width="thin"
              >
                <Menu.Item color={"blue"} as="a" onClick={this.gotoHomePage}>
                  <Image
                    size={"tiny"}
                    spaced={"left"}
                    src="/android-chrome-192x192.png"
                  />
                </Menu.Item>

                <Menu.Item color={"black"} as="a" onClick={this.gotoHomePage}>
                  <Icon name="home" />
                  Home
                </Menu.Item>
                <Menu.Item
                  color={"blue"}
                  as="a"
                  onClick={this.gotoBoatSecureWebSite}
                >
                  <Icon name="laptop" />
                  Website
                </Menu.Item>
                <Menu.Item as="a" onClick={this.emailUs}>
                  <Icon name="mail" />
                  Email Us
                </Menu.Item>
                {this.state.isAuthenticated && this.state.userid != "" ? (
                  <Menu.Item as="a" onClick={() => this.handleLogout()}>
                    <Icon size="tiny" name="log out" />
                    Log Out
                  </Menu.Item>
                ) : null}
                <Menu.Item>
                  <Icon name="file alternate" />
                  Version 1.9
                  <Label attached="bottom" size="mini">
                    {" "}
                    <Icon name="copyright outline" />
                    BoatSecure Ltd 2019
                  </Label>
                </Menu.Item>
              </Sidebar>

              <Sidebar.Pusher dimmed={visible}>
                <Segment>
                  {this.state.isAuthenticated && this.state.userid != "" ? (
                    <Main
                      sendLastNavigatedLocation={this.sendLastNavigatedLocation}
                      userid={this.state.userid}
                      headers={this.state.headers}
                      fleetViewSelected={this.state.fleetViewSelected}
                      changeHomePageSelected={this.changeHomePageSelected}
                      homePageSelected={this.state.homePageSelected}
                    />
                  ) : (
                    <LoginForm
                      userHasAuthenticated={this.userHasAuthenticated}
                      props={childProps}
                      headers={this.state.headers}
                      verificationCode={verificationCode}
                    />
                  )}
                </Segment>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </div>
        )}
      </div>
    );
  }
}
