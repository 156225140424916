import Auth from "@aws-amplify/auth";
import { API } from "aws-amplify";

export const cognitoForgotPasswordSendCode = username => {
  let result = { status: "", response: "" };
  Auth.forgotPassword(username)
    .then(data => {
      result.status = true;
      result.response = data;
    })
    .catch(err => {
      result.status = false;
      result.response = err;

      return result;
    });
};
export const cognitoConfirmUser = (username, code) => {
  // After retrieving the confirmation code from the user
  Auth.confirmSignUp(username, code, {
    // Optional. Force user confirmation irrespective of existing alias. By default set to True.
    // forceAliasCreation: true
  })
    .then(data => console.log(data))
    .catch(err => console.log(err));
};

export const cognitoforgotPasswordSubmit = (username, code, new_password) => {
  let result = false;
  Auth.forgotPasswordSubmit(username, code, new_password)
    .then(data => {
      // console.log(data);
      result = true;
      return { result, data };
    })
    .catch(err => {
      //  console.log(err);
      return { result, err };
    });
};

export const emailVerification = email => {
  if (
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const cognitoChangePassword = (oldPassword, newPassword) => {
  Auth.currentAuthenticatedUser()
    .then(user => {
      return Auth.changePassword(user, "oldPassword", "newPassword");
    })
    .then(data => console.log(data))
    .catch(err => console.log(err));
};

export const apiPostMethod = (path, body) => {
  let result = API.post("boatsecure", path, {
    body: body
  });
  return result;
};

export const apiGetMethod = async (path, queryStringParameters) => {
  let result = await API.get("boatsecure", path, {
    queryStringParameters: queryStringParameters
  });
  return result;
};

export const selectView = {
  FleetList: "FleetList",
  BoatList: "BoatList",
  BoatOverview: "BoatOverview",
  BoatDetailedOverview: "BoatDetailedOverview",
  DataLogView: "DataLogView",
  Test: "Test"
};

export const getDateFormat = date => {
  let formattedDate = new Date(date).toString();
  return formattedDate;
};

export const getVoltageChartDetails = (data, durationSelector) => {
  let intervals = [];
  let batteryVoltageList = [];
  let internalVoltageList = [];
  let measurement;
  var options = { hour12: false };
  for (measurement of data) {
    // 168 = 7 day * 24 hours
    // //  if (parseInt(durationSelector) >= 168) {
    let interval = dateWithoutYearFormat(measurement.created_at);
    // let interval = new Date(measurement.created_at).toString().substring(4, 10)+ ' ' +new Date(measurement.created_at).toString().substring(16, 21);
    // let interval = new Date(measurement.created_at).toString().substring(4, 21);
    intervals.push(interval);
    // // } else {

    //  let time = new Date(measurement.created_at).toLocaleTimeString('en-US', options)
    //  intervals.push(time.substring(0, 5))
    ////  // intervals.push( time.substring(0, 5) + time.substring(time.length-2, time.length)
    //  );
    ////  }
    batteryVoltageList.push(measurement.stype[2].value / 1000);
    internalVoltageList.push(measurement.stype[1].value / 1000);
  }
  return { intervals, batteryVoltageList, internalVoltageList };
};
export const getVoltageChartDetailsGoogle = (data, durationSelector) => {
  // let intervals = [];
  // let batteryVoltageList = [];
  // let internalVoltageList = [];
  let intervals = "";
  let batteryVoltage = "";
  let internalVoltage = "";
  let measurement;
  let voltData = [["", "Boat Battery", "Internal Battery"]];
  var options = { hour12: false };
  for (measurement of data) {
    let interval = dateWithoutYearFormat(measurement.created_at);
    let batteryVoltage = measurement.stype[2].value / 1000;
    let internalVoltage = measurement.stype[1].value / 1000;
    voltData.push([interval, batteryVoltage, internalVoltage]);
  }
  return { voltData };
};

export const dateWithoutYearFormat = date => {
  let formattedDate =
    new Date(date).toString().substring(4, 10) +
    " " +
    new Date(date).toString().substring(16, 21);
  return formattedDate;
};

export const getBilgePumpChartDetails = (data, durationSelector) => {
  let intervals = [];
  let BilgePumpOnList = [];
  let measurement;
  let groupInterval = "";
  let groupBilgePumpData = 0;
  for (measurement of data) {
    if (parseInt(durationSelector) <= 24) {
      intervals.push(dateWithoutYearFormat(measurement.created_at));
      BilgePumpOnList.push(minuteSecond(groupBilgePumpData));
      //  BilgePumpOnList.push(groupBilgePumpData/60);
    } else {
      groupBilgePumpData += measurement.stype[3].value;
      if (
        groupInterval !=
          convertDBDatetoformat(measurement.created_at).substring(0, 6) &&
        groupInterval != ""
      ) {
        minuteSecond(groupBilgePumpData);
        BilgePumpOnList.push(minuteSecond(groupBilgePumpData));
        //BilgePumpOnList.push(groupBilgePumpData/60);
        groupInterval = "";
        groupBilgePumpData = 0;
      } else {
        if (groupInterval === "") {
          groupInterval = convertDBDatetoformat(
            measurement.created_at
          ).substring(0, 6);
          intervals.push(groupInterval);
        }
      }
    }
  }
  if (BilgePumpOnList.length == 0) {
    BilgePumpOnList.push(groupBilgePumpData);
  }
  return { intervals, BilgePumpOnList };
};

export const getBilgePumpChartDetailsGoogleCharts = (
  data,
  durationSelector
) => {
  let intervals = [];
  let BilgePumpOnList = [];
  let measurement;
  let groupInterval = "";
  let groupBilgePumpData = 0;
  let intervalData = 0;
  let measurementData = 0; 
  let bilgePumpData = [["", "Bilge Pump"]]; 

 if (parseInt(durationSelector) <= 24) { 
  for (measurement of data) {
    intervalData = dateWithoutYearFormat(measurement.created_at);
      measurementData = minuteSecond(measurement.stype[3].value); 
      bilgePumpData.push([intervalData, measurementData]);
  }}else{
    for (measurement of data) {
      groupBilgePumpData += measurement.stype[3].value;
      if (
        groupInterval !=
        convertDBDatetoformat(measurement.created_at).substring(0, 6) &&
        groupInterval != ""
      ) {
        measurementData = minuteSecond(groupBilgePumpData);
        bilgePumpData.push([intervalData, measurementData]);

        groupInterval = "";
        groupBilgePumpData = 0;
      } else {
        if (groupInterval === "") {
          groupInterval = convertDBDatetoformat(
            measurement.created_at
          ).substring(0, 6);
          intervalData = groupInterval;
        }
      }
    }
    bilgePumpData.push([intervalData, measurementData]);
  }
     
  // if (BilgePumpOnList.length == 0) {
  //   BilgePumpOnList.push(groupBilgePumpData);
  // }
  return { bilgePumpData };
};
// export const getBilgePumpChartDetailsGoogleCharts = (
//   data,
//   durationSelector
// ) => {
//   let intervals = [];
//   let BilgePumpOnList = [];
//   let measurement;
//   let groupInterval = "";
//   let groupBilgePumpData = 0;
//   let intervalData = 0;
//   let measurementData = 0;
//   // let bilgePumpData=[]
//   let bilgePumpData = [["", "Bilge Pump"]]; 
//   for (measurement of data) {
//    // console.log('measure of data',measurement)
//     if (parseInt(durationSelector) <= 24) {
//       intervalData = dateWithoutYearFormat(measurement.created_at);
//       measurementData = minuteSecond(measurement.stype[3].value);
//       // measurementData = minuteSecond(groupBilgePumpData);
//       bilgePumpData.push([intervalData, measurementData]);
//     //  console.log('bilg pump data <=24', measurementData)
//     } else {
//       groupBilgePumpData += measurement.stype[3].value;
//       // console.log('bilg pump data  4', measurementData)
//       // console.log('====================================');
//      console.log('measurement created at ',measurement.created_at,' and formatted is ',  convertDBDatetoformat(measurement.created_at).substring(0, 6));
//       // console.log(new Date(measurement.created_at).toUTCString())
//       // console.log('====================================',);
//       if (
//         groupInterval !=
//         convertDBDatetoformat(measurement.created_at).substring(0, 6) &&
//         groupInterval != ""
//       ) {
//         measurementData = minuteSecond(groupBilgePumpData);
//         bilgePumpData.push([intervalData, measurementData]);

//         groupInterval = "";
//         groupBilgePumpData = 0;
//       } else {
//         if (groupInterval === "") {
//           groupInterval = convertDBDatetoformat(
//             measurement.created_at
//           ).substring(0, 6);
//           intervalData = groupInterval;
//         }
//       }
//     //  bilgePumpData.push([intervalData, measurementData]);
//     }
//   }
//   if (BilgePumpOnList.length == 0) {
//     BilgePumpOnList.push(groupBilgePumpData);
//   }
//   return { bilgePumpData };
// };

export const minuteSecond = duration => {
  var mins = ~~(duration / 60);
  var secs = ~~duration % 60;
  var ret = "";
  ret += "" + mins + "." + (secs < 10 ? "0" : "");
  ret += "" + secs;
  ret = parseInt(ret);
  return ret;
};

export const BilgePumpConvertDBDatetoformat = date => {
 //  let formattedDate = new Date(date).toString().substring(4, 15);
   let UTCDate = new Date(date).toUTCString().toString() 
    let month = UTCDate.substring(8,11)
    let dateIndex = UTCDate.substring(5,7)
    let   formattedDate =  month+dateIndex 
    console.log('index value',formattedDate);
  return formattedDate;
};
export const convertDBDatetoformat = date => {
   let formattedDate = new Date(date).toString().substring(4, 15);
    
  return formattedDate;
};
export const convertDBDatetoformatWithTime = date => {
  let formattedDate = new Date(date).toString().substring(4, 21);
  return formattedDate;
};

export const dateTo_YYYYMMDD_format = date => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const dateFormatDataLog = date =>{
console.log(' date to be formatted in the data log page',date)
//convertDBDatetoformat
let formattedDDMM = new Date(date).toString().substring(4,10)
let formattedTime = new Date(date).toString().substring(15,21)
let formattedDate = formattedDDMM+formattedTime 
return formattedDate
}
