import React, { Component } from 'react';
import { Button, Header,Message, Form,  Modal,List } from 'semantic-ui-react';
import Auth from '@aws-amplify/auth';
import {   JS  } from '@aws-amplify/core';

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isParentOpen: this.props.forgotPasswordModalOpen,
            userName: this.props.CognitoUser.username,
            modalContent: "Forgot Password",
            email: "",
            entered_username: "",
            forgotpwdmessage: "",
            forgotPasswordError: false,
            forgotPasswordErrormsg: false,
            password: "",
            confirmPassword: "",
            confirmationCode: "",
            cognitoUser: this.props.CognitoUser,
            submitForgotUsername: false,
            passwordVisible: this.props.disable
        };
        this.handleForgotPassword = this.handleForgotPassword.bind(this);
        this.submitForgotPassword = this.submitForgotPassword.bind(this);
        this.handleVerificationCode = this.handleVerificationCode.bind(this);
        this.handleChangeUserPassword = this.handleChangeUserPassword.bind(this);
        this.handleChangeUserCofirmPassowrd = this.handleChangeUserCofirmPassowrd.bind(this);
    }
    componentDidMount() {
        console.log("1222222222222222222222222", this.props);
        console.log("forgot password copy mounted", this.props);
        if (this.props.disable === true) {
            this.setState({
                modalContent: "You have to set a new Password"
            });
        }
        if (this.props.verificationCode != null && this.props.verificationCode != '') {
            this.setState({
                confirmationCode: this.props.verificationCode,
                submitForgotUsername: true,
                passwordVisible: true,
                forgotpwdmessage: 'Enter your Username and update your password.Please note Username is case sensitive'
            });
        } else {
            this.setState({
                confirmationCode: '',
                submitForgotUsername: false,
                passwordVisible: false,
                forgotpwdmessage:'Enter your Username and submit.You will receive an email with verification link and use that link to update the password.Please note Username is case sensitive'
            });
        }
    }

    handleCloseForgotPasswordModal = () => {
        this.setState({
            isParentOpen: false
        });
        this.props.closeModal();
    }
   


    handleChange = event => {
        this.setState({
            entered_username: event.target.value
        });

    }
    handleVerificationCode = event => {
        this.setState({
            confirmationCode: event.target.value
        });

    }
    handleChangeUserPassword = event => {
        this.setState({
            password: event.target.value
        });

    }
    handleChangeUserCofirmPassowrd = event => {
        this.setState({
            confirmPassword: event.target.value
        });

    }
    
    checkContact(user) {
        if (!Auth || typeof Auth.verifiedContact !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        Auth.verifiedContact(user)
            .then(data => {
                if (!JS.isEmpty(data.verified)) {
                    this.changeState('signedIn', user);
                } else {
                    user = Object.assign(user, data);
                    this.changeState('verifyContact', user);
                }
            });
    }


    handleChangePassword = async event => {
        event.preventDefault();
        this.setState({
            passwordVisible: true
        });
         if (this.state.password === this.state.confirmPassword) {            
        
            const { requiredAttributes }  = this.state.cognitoUser.challengeParam;
            const attrs = objectWithProperties(this.state.password, requiredAttributes);
             try {
                 console.log("this.state.cognitoUser")
                console.log("force password try")
                await Auth.completeNewPassword(this.state.cognitoUser, this.state.password, attrs)
                    .then((data) => {
                        console.log('data',data)
                        this.props.callBackUserAuthentication(data);
                    });
            }
            catch (e) {
               // console.log("force password catch",e)
            }
        }
        else {
          //console.log("confirm password doesnt match")
        }
    }


    handleForgotPassword = () => {
        this.setState({
            submitForgotUsername: true,
            passwordVisible: true
        });
        console.log("this.state.entered_username ", this.state.entered_username);
        Auth.forgotPassword(this.state.entered_username)
            .then(data => {
                console.log("data ", data);
            })
    }

    async submitForgotPassword() {
        console.log("state", this.state);
        // this.props.closeModal();
        await Auth.forgotPasswordSubmit(
            this.state.entered_username,
            this.state.confirmationCode,
            this.state.password
        )
            .then(data => {
                console.log("data", data);
               
                try {
                    this.props.closeModal();
                    Auth.signIn(this.state.entered_username, this.state.password).then(
                        cognitoUser => {
                            this.setState({
                                forgotPasswordError: false,
                                login_error: false
                            }); 
                            window.history.pushState({}, document.title, window.location.pathname);
                            if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
                                this.setState({
                                    cognitoUser: cognitoUser,
                                });
                            } else {
                                console.log("cognitoUser", cognitoUser);
                                this.setState({
                                    cognitoUser: cognitoUser
                                });
                                this.props.callBackUserAuthentication(cognitoUser);
                            }
                        }
                    );
                   
                } catch (e) {
                    console.log("error", e);
                    this.setState({
                        isLoading: false,
                        login_error: true
                    });
                }
                
       }).catch(err => {
           console.log("err", err);
           var error;
           if (typeof err.message === 'undefined') {
               error = err
           } else {
               error = err.message
           }
           console.log("err", err.message);
           console.log("error", error);
           this.setState({
               forgotPasswordError: true,
               forgotPasswordErrormsg: error,
               login_error: true
           });
                //this.setState({
                //    forgotPasswordError: true,
                //    messageInfoType: false,
                //    messageNegativeType: true,
                //    messageContent: err.message
                //});
       });
    };


    render() {
        return (

            <Modal
                size={"tiny"}
                dimmer={"blurring"}
                open={this.state.isParentOpen}
                centered={false}
                closeIcon
                onClose={this.handleCloseForgotPasswordModal}
            >
                <Header icon='key' content={this.state.modalContent} />
                <Modal.Content>
                    {this.state.forgotPasswordError ?
                    <Message color='red' info size={'tiny'}>
                            <p>{this.state.forgotPasswordErrormsg}</p>
                        </Message>
                    : null}
                    <Message color='teal' info size={'tiny'}>
                        <p>{this.state.forgotpwdmessage}.</p>
                    </Message>
                    <Form >
                        <Form.Field>
                            <label
                                color='teal'
                            >
                                Username
                                </label>
                            <input
                                placeholder='Enter your Username'
                                readOnly={this.props.disable}
                                defaultValue={this.state.userName}
                                onChange={this.handleChange}
                                id='userName'
                            />
                            {this.state.submitForgotUsername ?
                                <div>
                                    <label >Verification Code that was sent to your email</label>
                                    <input placeholder='Enter the Verification Code'
                                        onChange={this.handleVerificationCode}
                                        value={this.state.confirmationCode}
                                        
                                    />
                                </div>
                                : null}
                            {this.state.passwordVisible ?

                                < div >
                                    
                                    <label >Enter new password</label>
                                    <input
                                        placeholder='Enter new password'
                                        type='password'
                                        defaultValue={this.state.password}
                                        onChange={this.handleChangeUserPassword}
                                        id='password'
                                    />
                                    <label >Confirm new password</label>
                                    <input
                                        placeholder='Confirm new password'
                                        type='password'
                                        defaultValue={this.state.confirmPassword}
                                        onChange={this.handleChangeUserCofirmPassowrd}
                                        id='confirmPassword'
                                    />

                                    <Message compact color='orange'>
                                        You have to set a new password.{' '}The length must be at least 8 characters and It must contain at least one of each:<br/>
                                    <List as='ol'>
                                        <List.Item as='li' value='*'>
                                            a lowercase letter
                        </List.Item>
                                        <List.Item as='li' value='*'>
                                            a uppercase letter
                        </List.Item>
                                        <List.Item as='li' value='*'>
                                            a number
                        </List.Item>
                                        </List>
                                    </Message>
                                </div>
                                
                                :null
                            }



                        </Form.Field>
                    </Form>
                    
                    
                </Modal.Content>
                <Modal.Actions>

                    <Button.Group size='large'>
                        {/*        <Button
                            basic color='black'
                            type='submit'

                        >
                            Cancel
                            </Button>
                        <Button.Or />  */}

                        {this.props.disable ?
                            <div>
                                <Button
                                    basic color='blue'
                                    type='submit'
                                    onClick={this.handleChangePassword}
                                >
                                    Submit
                            </Button>
                            </div>
                            :
                            !this.state.submitForgotUsername ?
                                <div>
                                    <Button
                                        basic color='blue'
                                        type='submit'
                                        onClick={this.handleForgotPassword}
                                    >
                                        Submit forgot
                            </Button>
                                </div>
                                :

                                <div>
                                    <Button
                                        basic color='green'
                                        type='submit'
                                        onClick={this.submitForgotPassword}
                                    >
                                        Confirm
                            </Button>
                                </div>
                        }

                    </Button.Group>
                </Modal.Actions>
            </Modal>


        )
    }
}

function objectWithProperties(obj, keys) {
    const target = {};
    for (const key in obj) {
        if (keys.indexOf(key) === -1) {
            continue;
        }
        if (!Object.prototype.hasOwnProperty.call(obj, key)) {
            continue;
        }
        target[key] = obj[key];
    }
    return target;
}