import React, { Component, PureComponent } from "react";
//// import ReactEcharts from "echarts-for-react";
// import ReactEchartsCore from "echarts-for-react/lib/core";
// import echarts from "echarts/lib/echarts";
// import "echarts/lib/chart/bar";
// import "echarts/lib/component/tooltip";
//// import "echarts/lib/component/title";
import Chart from "react-google-charts";
import { getBilgePumpChartDetailsGoogleCharts } from "../../modules/data";
import { Header } from "semantic-ui-react";

export default class BilgePumpChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { chartData: [] };
  }
  async componentDidMount() {
    let chartData = await getBilgePumpChartDetailsGoogleCharts(
      this.props.boatData,
      this.props.durationSelector
    ); 
    
    this.setState({
      chartData: chartData.bilgePumpData
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    let chartData = [];
    if (prevProps != this.props) {
      chartData = await getBilgePumpChartDetailsGoogleCharts(
        this.props.boatData,
        this.props.durationSelector
      );
      let test = chartData.bilgePumpData;
      this.setState({
        chartData: test
      });
    }
  }

  getOption = async () => {
    let chartData = await getBilgePumpChartDetailsGoogleCharts(
      this.props.boatData,
      this.props.durationSelector
    );
    console.log("componentDidUpdate", chartData);

    let test = chartData.bilgePumpData; 

    return test;
  };
  render() {
    return (
     
      <div
   
     // style={{ display: "flex", maxWidth: 950 }}
      >
        <Chart
        //  width={"650px"}
          height={"300px"}
          chartType="ColumnChart"
          loader={<div>Loading Chart</div>}
          //  data={this.getOption()}
          data={this.state.chartData}
          options={{
            backgroundColor: "#e0fffd",
            legend: { position: "top" },
            title: "Bilge Pump",
            focusTarget: 'category',
            chartArea: { width: "85%", height: "60%" },
            //tooltip: {trigger: 'selection'}, 
            // hAxis: {
            //   //title: "Time"

            // }
            vAxis: {
              minValue: 0,
              maxValue: 5,
              title: "Minutes"
            } 
          }}
          rootProps={{ 'data-testid': '2' }}
        />
     {/* // </div> */}
       </div>
    );
  }
}
