import React, { Component } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
  
} from "semantic-ui-react";

import ForgotPassword from "./ForgotPassword";
import Password from "./common/Password";
import Auth from "@aws-amplify/auth";
import { API } from "aws-amplify"; 
const uuid = require("uuid/v4");
const boatsecurelogo = require("../images/boatsecurelogo.png");

export default class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: "",
      password: "",
      forgotPasswordModalOpen: false,
      forgotPasswordUserNameDisable: false,
      sessionDetails: this.props.sessionDetails,
      loginDetail: {
        userName: "",
        userObject: [],
        sessioToken: ""
      },
      cognitoUser: [],
      login_error: false
    };
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.HandleForgotPasswordModalOpen = this.HandleForgotPasswordModalOpen.bind(
      this
    );
    this.HandleForgotPasswordModalClose = this.HandleForgotPasswordModalClose.bind(
      this
    );
    this.userAuthentication = this.userAuthentication.bind(this);
    //this.handleUpdateSession = this.handleUpdateSession.bind(this);
  }
    componentDidMount() { 
        console.log("this.props", this.props);
        if (this.props.verificationCode != null && this.props.verificationCode != '' && this.props.verificationCode.length > 0) {
            this.setState({
                forgotPasswordModalOpen: true
            });
        } else {
            this.setState({
                forgotPasswordModalOpen: false
            });
        }
  }
  HandleForgotPasswordModalOpen() {
    this.setState({
      forgotPasswordModalOpen: true
    });
    
  }

  HandleForgotPasswordModalClose() {
    this.setState({
      forgotPasswordModalOpen: false,
      forgotPasswordUserNameDisable: false
    });
  }
  handlePasswordChange(e) {
    this.setState({ password: e.target.value }); 
  }
  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    };

    userAuthentication(e) {
    console.log("e", e);
        this.props.userHasAuthenticated(true,e);
   }

  //handleUpdateSession(event) {
  //  const sessionPayload = event.getIdToken().decodePayload();
  //  const session_id = uuid();
  //  console.log(" sessionPayload", sessionPayload);
  //  console.log("session_id", session_id);
    
  //  let myInit = {
  //    response: true // OPTIONAL (return the entire Axios response object instead of only response.data)
  //    //  body: sessionPayload,
  //    // queryStringParameters: {  // OPTIONAL
  //    //     session_id: session_id
  //    // }
  //  };

  //  const path = `/session/${session_id}`; 

  //    API.get("boatsecure", path, myInit)
  //    .then(response => {
  //      // Add your code here
  //    })
  //    .catch(error => {
  //      console.log(error.response);
  //    });
  //}

    

  handleSubmit = async event => {
    event.preventDefault(); 
    try {
      await Auth.signIn(this.state.email, this.state.password).then(
        cognitoUser => {
          if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
            this.setState({
              cognitoUser: cognitoUser,
              forgotPasswordUserNameDisable: true
            });

            this.HandleForgotPasswordModalOpen();
          } else {
            this.setState({
              cognitoUser: cognitoUser
            });
            // sessionTEST = Auth.currentSession()
            //Auth.currentSession().then(data => {
            //  this.handleUpdateSession(data);
            //  console.log("data", data);
            //}); 
              this.props.userHasAuthenticated(true, cognitoUser);
          }
        }
      );

      //this.props.userHasAuthenticated(true);
      //alert("Logged in");
    } catch (e) {
        console.log("error", e);
      this.setState({ 
        isLoading: false,
        login_error:true
       });
     }
  };

  render() {
    return (
      <div className="login-form">
        {/*      <style>{`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}</style>    */}
        <Grid
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" color="teal" textAlign="center">
              {/* <Image src='src/images/boatsecurelogo.png' /> Log-in to your account  */}
            </Header>
            <Form size="large" onSubmit={this.handleSubmit}>
          
              <Segment stacked>
              {this.state.login_error ? (
            <Message negative>
              <Message.Header>
                Incorrect login credentials
              </Message.Header>
              <p></p>
            </Message>
          ) : null}
                <Form.Input
                  fluid
                  icon="user"
                  id="email"
                  iconPosition="left"
                  placeholder="Username or E-mail"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                <Password
                  controlFunc={this.handlePasswordChange}
                  password={this.state.password}
                  placeholder={"Password"}
                  fromlogin={true}
                />

                <Button
                  color="blue"
                  fluid
                  size="large"
                  type="submit"
                  //disabled={!this.validateForm()}
                >
                  Login
                </Button>
                        </Segment>
                       
            </Form>
            <Message>
              <Button
                basic
                color="blue"
                fluid
                size="large"
                onClick={this.HandleForgotPasswordModalOpen}
              >
                Forgot Password
              </Button>
                    </Message>
                    <Image src={boatsecurelogo} centered />
          </Grid.Column>
        </Grid>

        {this.state.forgotPasswordModalOpen ? (
          <ForgotPassword
            closeModal={this.HandleForgotPasswordModalClose}
            forgotPasswordModalOpen={this.state.forgotPasswordModalOpen}
            disable={this.state.forgotPasswordUserNameDisable}
            CognitoUser={this.state.cognitoUser}
            callBackUserAuthentication={this.userAuthentication}
            verificationCode={this.props.verificationCode}
          />
        ) : null}
      </div>
    );
  }
}
