import React, { Component } from "react";
import { Segment } from "semantic-ui-react";
import BoatDetailedOverview from "./BoatDetailedOverview";
import BoatOveriew from "./BoatOverview";
import Fleetlist from "./Fleetlist";
import Boatlist from "./Boatlist"; 
import DataLogView from "./LatestEventsTable";
import { apiGetMethod } from "../modules/data";
import { API } from "aws-amplify";

export default class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayView:
        this.props.fleetViewSelected == null
          ? ""
          : this.props.fleetViewSelected == true
          ? "FleetList"
          : "",
      selectedFleetsBoatList: [],
      homePageSelected: false,
        selectedBoat: {},
      fleetlist: [],
      selectedFleet: [],
      boatDataForDuration: [],
      fleetListPageLoaderActive: true
    };

    this.sendUserLastLocation = this.sendUserLastLocation.bind(this);
      this.refreshPage = this.refreshPage.bind(this);
    }

    static getDerivedStateFromProps(props,state) { 
        var update = {};
            if (props.homePageSelected == true) {
                update.displayView = 'FleetList'
            }
        return update;
    }

 isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

  async componentDidMount() {
      //console.log("this.state", this.state);
    let fleetlist = [];
    try {
      fleetlist = await this.get_fleetData();
      console.log("fleetData", fleetlist);
      this.setState({
        fleetlist: fleetlist,
        fleetListPageLoaderActive: false
        });
        
    } catch (err) {}
  }
    async refreshPage() {
        //window.location.reload(true); 
        var fleetlist = await this.get_fleetData();
        console.log("fleetlist", fleetlist);
        console.log("selectedFleetsBoatList", this.state.selectedFleetsBoatList);
        console.log("selectedBoat", this.state.selectedBoat);
        console.log("selectedFleet", this.state.selectedFleet);
        var user_selectedFleet=this.state.selectedFleet
        var updated_asset_boat_details = [];
        var seletcte_Fleet = {};
        for (let i = 0; i < fleetlist.length; i++) {
            if (fleetlist[i].asset_id == user_selectedFleet.asset_id ) {
                updated_asset_boat_details = fleetlist[i].asset_boat_details
                seletcte_Fleet = fleetlist[i]
            }
        }
        this.setState({
            selectedFleet: seletcte_Fleet
        });
        if (!this.isEmpty(this.state.selectedBoat)) {
            var userSelectedBoat = this.state.selectedBoat
            var updated_boat = {}
            for (let i = 0; i < updated_asset_boat_details.length; i++) {
                if (updated_asset_boat_details[i].device_id == userSelectedBoat.device_id) {
                    updated_boat = updated_asset_boat_details[i]
                }
            }
            this.setState({
                selectedBoat: updated_boat
            });
            
        }
        
        this.setState({
            fleetlist: fleetlist,
            selectedFleetsBoatList: updated_asset_boat_details
        });
        console.log("updated_asset_boat_details", updated_asset_boat_details);
    }
    get_fleetData = async () => {
    //alert(this.props.userid)
    let userid = this.props.userid;
    const path = `/boat/get_asset_details_by_user`;
    let queryStringParameters = {
      user_id: userid
    };

  //   fetch(Some_API).then(response=>{
  //     console.log(response.headers)
  // })
  //   let result = await API.get("boatsecure", path, {
  //     queryStringParameters: queryStringParameters
  //   }).then(response=>{
  //     console.log('response.headers',response)
  // });

 //   return result
     return apiGetMethod(path, queryStringParameters);
  };

  sendUserLastLocation(e) {
    console.log("data", e);
    this.props.sendLastNavigatedLocation(e);
  }

  selectViewhandler = (selectedView, selectedItem) => {
    switch (selectedView) {
      case "FleetList":
          this.setState({
              displayView: "FleetList"
            });
            this.props.changeHomePageSelected(false);
        break;
      case "BoatList":
        let selectedFleet = [];
        let selectedFleetsBoatList = [];
        if (selectedItem == null) {
          selectedFleetsBoatList = this.state.selectedFleetsBoatList;
          selectedFleet = this.state.selectedFleet;
        } else {
          selectedFleet = selectedItem;
          selectedFleetsBoatList = selectedItem.asset_boat_details;
        }
        this.setState({
          displayView: "BoatList",
          selectedFleetsBoatList: selectedFleetsBoatList,
            selectedFleet: selectedFleet
            
        });
            this.props.changeHomePageSelected(false);
        break;
      case "BoatOverview":
        let selectedBoat = [];
        if (selectedItem == null) selectedBoat = this.state.selectedBoat;
        else selectedBoat = selectedItem;
        this.setState({
          displayView: "BoatOverview",
            selectedBoat: selectedBoat
            
            });
            this.props.changeHomePageSelected(false);
            break;
            
      case "BoatDetailedOverview":
        this.setState({ displayView: "BoatDetailedOverview" });
            this.props.changeHomePageSelected(false);
        break;
      case "DataLogView":
        this.setState({
          displayView: "DataLogView",
            boatDataForDuration:   selectedItem
            });
            this.props.changeHomePageSelected(false);
        break; 
      default:
        this.setState({ displayView: "FleetList" });
    }
  };
  render() {
    const {
      displayView,
      selectedFleet,
      selectedBoat,
      fleetlist,
      fleetListPageLoaderActive
      } = this.state;
      console.log("props5555555555555555", this.props);
    return (
      <React.Fragment>
        <Segment color="blue" raised>
          {(() => {
            switch (displayView) {
              case "FleetList":
                return (
                    <Fleetlist
                        headers={this.props.headers}
                        callBack={this.selectViewhandler}
                        fleetlist={fleetlist}
                  />
                );
              case "BoatList":
                return (
                  <Boatlist
                        callBack={this.selectViewhandler}
                        sendUserLastLocation={this.sendUserLastLocation}
                        boatList={this.state.selectedFleetsBoatList}
                        selectedFleet={this.state.selectedFleet}
                        userid={this.props.userid}
                        fleetName={this.state.selectedFleet.asset_name}
                        refreshPage={this.refreshPage}
                  />
                );
              case "BoatOverview":
                return (
                  <BoatOveriew
                    callBack={this.selectViewhandler}
                    refreshOverViewPage={this.refreshPage}
                    selectedBoat={this.state.selectedBoat}
                    fleetName={this.state.selectedFleet.asset_name}
                  />
                );
              case "BoatDetailedOverview":
                return (
                  <BoatDetailedOverview
                    callBack={this.selectViewhandler}
                    refreshDetailoverViewPage={this.refreshPage}
                        selectedBoat={this.state.selectedBoat}
                        fleetName={this.state.selectedFleet.asset_name}
                  />
                );
              case "DataLogView":
                return (
                  <DataLogView
                    callBack={this.selectViewhandler}
                    refreshEventTablePage={this.refreshPage}
                    boatData={this.state.selectedBoat}
                    // boatData={this.state.boatDataForDuration}
                  />
                ); 
              default:
                return (
                  <Fleetlist
                    headers={this.props.headers}
                    callBack={this.selectViewhandler}
                    fleetlist={fleetlist}
                    fleetListPageLoaderActive={fleetListPageLoaderActive}
                  />
                );
            }
          })()}
        </Segment>
      </React.Fragment>
    );
  }
}
{
  /* */
}
