import React from "react";
import PropTypes from "prop-types";
import { Form, Input, Label } from "semantic-ui-react";

export default class Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      password_error: false,
      password_error_message: "",
      fromlogin: this.props.fromlogin
    };
    this.handlePasswordValidator = this.handlePasswordValidator.bind(this);
  }
  handlePasswordValidator(e) {
    console.log("handlePasswordValidator", e.target.value);
    console.log("fromlogin", this.state.fromlogin);

    if (!this.state.fromlogin) {
      if (
        /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d_@#$%!&)]{8,}$/.test(
          e.target.value
        )
      ) {
        this.setState({ password_error: false });
        this.props.controlFunc(e.trim());
      } else {
        this.setState({
          password_error: true,
          password_error_message: "Password should contain minimum eight characters, at least one letter and one number"});
      }
    }
   else{
    this.props.controlFunc(e);
   }
  }
  render() {
    const { values } = this.props;

    return (
      <Form.Field>
        <Input
          fluid
          icon="lock"
          iconPosition="left"
          placeholder={this.props.placeholder}
          type="password"
          className="form-input"
          name={this.props.name}
          value={this.props.content}
          // onChange={props.controlFunc}
          onBlur={this.handlePasswordValidator}
        />
        {this.state.password_error ? (
          <Label
            //  basic
            color="red"
            pointing
          >
            {this.state.password_error_message}
          </Label>
        ) : null}
      </Form.Field>
    );
  }
}
// const Password = (props) => (

//     <Form.Input
//         fluid
//         icon='lock'
//         iconPosition='left'
//         placeholder={props.placeholder}
//         type='password'

//         className="form-input"
//         name={props.name}
//         value={props.content}
//         onChange={props.controlFunc}
//     />

// );
Password.propTypes = {
  controlFunc: PropTypes.func.isRequired,
  password: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,

  placeholder: PropTypes.string
};

//export default Password;
